import React, { Component } from 'react';
import {
  Paper, Table, CircularProgress, TablePagination,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';


import TableToolbar from './table-toolbar';
import TableHead from './table-head';
// import Pagination from './pagination';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const TablePaginationActions = (props) => {
  const {
    onChangePage,
    // eslint-disable-next-line react/prop-types
    backIconButtonProps,
    count,
    // eslint-disable-next-line react/prop-types
    nextIconButtonProps,
    page,
    rowsPerPage,
    // eslint-disable-next-line react/prop-types
    theme,
    ...other
  } = props;
  return (
    <div {...other}>
      <IconButton
        onClick={event => onChangePage(event, 0)}
        disabled={page === 0}
        aria-label="First Page"
      >
        {/* {0} */}
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={event => onChangePage(event, page - 1)}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {/* {page - 1} */}
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={event => onChangePage(event, page + 1)}
        disabled={count === 0}
        aria-label="Next Page"
      >
        {/* {page + 1} */}
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};
TablePaginationActions.propTypes = {
  /**
   * Properties applied to the back arrow [`IconButton`](/api/icon-button/) element.
   */
  // backIconButtonProps: PropTypes.object, // PJL ??
  /**
   * The total number of rows.
   */
  count: PropTypes.number.isRequired,
  /**
   * Properties applied to the next arrow [`IconButton`](/api/icon-button/) element.
   */
  // nextIconButtonProps: PropTypes.object, // PJL ??
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback
   * @param {number} page The page selected
   */
  onChangePage: PropTypes.func.isRequired,
  /**
   * The zero-based index of the current page.
   */
  page: PropTypes.number.isRequired,
  /**
   * The number of rows per page.
   */
  rowsPerPage: PropTypes.number.isRequired,
};

class ADT extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    controls: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    columns: PropTypes.array.isRequired,
    componentName: PropTypes.string,
    toolbar: PropTypes.func,
    paginated: PropTypes.bool,
    containerClassName: PropTypes.string,
  };

  static defaultProps = {
    toolbar: () => { },
    componentName: '',
    paginated: true,
    containerClassName: '',
  };

  handleChangePage = (event, page) => {
    const { controls } = this.props;
    if (event) { // ignore pathological attempts by material UI to control the page
      controls.setPage(page);
    }
  };

  handleChangeRowsPerPage = (event) => {
    const { controls } = this.props;
    controls.params.rowsPerPage(event.target.value).execute();
  };

  handleTextSearch = (field, value, equalitySearch) => {
    const { controls } = this.props;
    if (equalitySearch) {
      if (value) {
        controls.params.addEqualityFilter(field, value).execute();
      } else {
        controls.params.removeEqualityFilter(field).execute();
      }
    } else if (value) {
      controls.params.addTextFilter(field, value).execute();
    } else if (controls.params.hasTextFilter(field)) {
      controls.params.removeTextFilter(field).execute();
    }
  };

  removeTextSearch = (field) => {
    const { controls } = this.props;
    if (controls.params.hasTextFilter(field)) {
      controls.params.removeTextFilter(field).execute();
    }
  }

  handleOrSearch = (field, options, values) => {
    const { controls } = this.props;

    // clear values
    options.forEach((option) => {
      const normalized_option = option.value.replace('.', '_').replace(' ', '_');
      const db_key = `${field}.${normalized_option}`;
      controls.params.removeEqualityFilter(`${db_key}.true`);
      controls.params.removeEqualityFilter(`${db_key}.false`);
    });

    if (values.length > 0) {
      options.forEach((option) => {
        const normalized_option = option.value.replace('.', '_').replace(' ', '_');
        const db_key = `${field}.${normalized_option}`;
        if (values.indexOf(option.value) >= 0) {
          controls.params.addEqualityFilter(`${db_key}.true`, true);
        } else {
          controls.params.addEqualityFilter(`${db_key}.false`, true);
        }
      });
    }

    controls.params.execute();
  };

  searchValue = (field) => {
    const { data } = this.props;
    const { params } = data;
    if (params.textFilter && params.textFilter[field]) {
      return params.textFilter[field];
    }
    if (params.equalityFilter && params.equalityFilter[field]) {
      return params.equalityFilter[field];
    }
    return '';
  };

  setOrder = (event, property) => {
    const orderByProp = property;
    let orderProp = 'desc';
    if (this.orderBy() === property && this.orderDirection() === 'desc') {
      orderProp = 'asc';
    }
    const { controls } = this.props;
    controls.params.orderBy([orderByProp, orderProp]).execute();
  };

  orderBy() {
    const { data } = this.props;
    return (data.params && data.params.orderBy && data.params.orderBy[0]) || '';
  }

  orderDirection() {
    const { data } = this.props;
    return (data.params && data.params.orderBy && data.params.orderBy[1]) || 'asc';
  }

  rowsPerPage() {
    const { data } = this.props;
    return data.params && data.params.rowsPerPage;
  }

  page() {
    const { data } = this.props;
    return data.params && data.params.page;
  }

  render() {
    const {
      data,
      controls,
      classes,
      children,
      componentName,
      columns,
      toolbar,
      paginated,
      containerClassName,
    } = this.props;
    return (
      <div className={containerClassName}>
        {data.isLoading && (
          <Paper elevation={2} className={classes.root}>
            {/* The circular progress indicator doesn't always appear where the
            table will go, we need a better indicator */}
            <div id="loading">Loading...</div>
            <CircularProgress className={classes.progress} size={100} />
          </Paper>
        )}
        {!data.isLoading && (
          <Paper elevation={2} className={classes.root}>
            <TableToolbar classes={classes} componentName={componentName} render={toolbar} />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <TableHead
                  order={this.orderDirection()}
                  orderBy={this.orderBy()}
                  onRequestSort={this.setOrder}
                  rowCount={data.rows.length}
                  columns={columns}
                  controls={controls}
                  handleTextSearch={this.handleTextSearch}
                  handleOrSearch={this.handleOrSearch}
                  removeTextSearch={this.removeTextSearch}
                  searchValue={this.searchValue}
                />
                {children}
              </Table>
            </div>
            {paginated && (
              <TablePagination
                component="div"
                // use count as a flag for whether there is a next page
                count={data.hasNextPage ? 1 : 0}
                rowsPerPage={this.rowsPerPage()}
                page={data.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                labelDisplayedRows={() => (
                  <div>
                    {`Page ${1 + data.page}`}
                  </div>
                )}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ADT);
