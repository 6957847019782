import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TableBody,
  TableCell,
  TableRow,
  Button,
} from '@material-ui/core';
import { AddComment } from '@material-ui/icons';
import { rowStyle, even } from '../../rowStyle';
import LoadFormModal from './load_form_modal';
import { formatDate, calculateAging, formatCurrency } from '../../utils';

const mapStatus = (status) => {
  const status_map = {
    paid: 'Paid',
    issue: 'There is an issue',
    going_to_pay: 'Going to pay',
  };
  return status_map[status];
};

const LoadsTableBody = ({
  classes, data, openModal,
}) => (
  <TableBody>
    {data.map((n, i) => (
      <TableRow hover key={n.id} classes={even(i, classes)}>
        <TableCell component="th" scope="row" size="small">
          {n.referenceno}
        </TableCell>
        <TableCell component="th" scope="row" size="small">
          {n.display_id}
        </TableCell>
        <TableCell size="small">{n.mc_authority}</TableCell>
        <TableCell size="small">{calculateAging(n.customer_due_date)}</TableCell>
        <TableCell size="small">
          {formatDate(n.customer_due_date)}
        </TableCell>
        <TableCell size="small">
          {formatCurrency(n.transaction_data ? n.transaction_data.customer_debt : 0)}
        </TableCell>
        <TableCell size="small">
          {n.customer_feedback ? mapStatus(n.customer_feedback.payment_status) : ''}
        </TableCell>
        <TableCell size="small">
          <Button
            size="small"
            onClick={() => openModal(
              <LoadFormModal id={n.id} tab="edit" load_id={`${n.id}`} data={n.customer_feedback || {}} />,
            )}
          >
            <AddComment />
          </Button>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

LoadsTableBody.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default withStyles(rowStyle)(LoadsTableBody);
