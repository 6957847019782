module.exports = {
  apiKey: 'AIzaSyDglHUghPkOXZKatadlWiO3JJ4sklqMXC0',
  authDomain: 'alpaca-prod.firebaseapp.com',
  databaseURL: 'https://alpaca-prod.firebaseio.com',
  projectId: 'alpaca-prod',
  storageBucket: 'alpaca-prod.appspot.com',
  messagingSenderId: '392677137361',
  appId: '1:392677137361:web:f0d05e8f38ae0c39',
  bigquery_url: 'https://alpaca-dev-240523.appspot.com',
};
