export const US = 'United States';
export const containerSpacing = 3;
export const role_names = [
  { value: 'admin', label: 'Administrator' },
  { value: 'agent', label: 'Agent' },
  { value: 'hr', label: 'Human Resources' },
  { value: 'operations', label: 'Operations' },
  { value: 'office_manager', label: 'Office Manager' },
  { value: 'driver', label: 'Driver' },
  { value: 'customer', label: 'Customer' },
  { value: 'accounting', label: 'Accounting' },
  { value: 'carrier_manager', label: 'Carrier Manager' },
  { value: 'customer_manager', label: 'Customer Manager' },
  { value: 'ar_manager', label: 'Accounts Receivable Manager' },
  { value: 'ar_agent', label: 'Accounts Receivable Agent' },
];
