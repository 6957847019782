
import FirestoreQuery from './firestore-query';
import MemoryProcessor from './memory-processor';

class QueryStrategy {
  constructor(firestore, indexInfo, listener) {
    this.memoryLimit = 500;
    this._firestoreQuery = new FirestoreQuery(firestore);
    this._memoryProcessor = new MemoryProcessor();
    this.listener = listener;
    this._indexInfo = indexInfo;
    this._params = {
      firestore: null,
      memory: null,
    };
    this._lastSnap = {
      docs: [],
    };
    this._page = 0;
    this._pageStack = [];
    this._calculatedColumns = {};
  }

  _listenerConnector = (snap) => {
    if (!snap) {
      throw new Error('query strategy listener called without snapshot');
    }
    if (!this._listener) {
      return;
    }
    this._lastSnap = snap;
    this._memoryProcessor.updateData(snap.docs);
  }

  _adaptListener() {
    if (this._listener) {
      this._memoryProcessor.listener = this._listener;
      this._firestoreQuery.listener = this._listenerConnector;
    }
  }

  _forceFire() {
    if (this._listener) {
      this._listenerConnector(this._lastSnap);
    }
  }

  getPage() {
    if (this._params.memory) {
      return this._memoryProcessor.getPage();
    }
    return this._firestoreQuery.getPage();
  }

  setPage(pageNum) {
    if (typeof pageNum !== 'number' || pageNum < 0) {
      throw new Error(`Invalid page number request ${pageNum}`);
    }

    if (this._params.memory) {
      this._page = this._memoryProcessor.setPage(pageNum);
    } else {
      this._page = this._firestoreQuery.setPage(pageNum);
    }
    if (this._page !== pageNum) {
      throw new Error(`Unable to set page to ${pageNum}`);
    }
    setTimeout(() => {
      this._forceFire();
    });
  }

  hasNextPage() {
    if (this._params.memory) {
      return this._memoryProcessor.hasNextPage();
    }
    return this._firestoreQuery.hasNextPage();
    // return this._lastSnap.docs.length === this._params.firestore.limit;
  }

  set listener(newListener) {
    this._listener = newListener;
    this._adaptListener();
  }

  get isLoading() {
    return this._firestoreQuery.isLoading;
  }

  _planParams(params) {
    const { collection, doc, sub_collection } = params;
    const fireinfo = Object.assign({}, this._indexInfo.firestore);
    const collinfo = Object.assign({
      equalityFilter: [],
      rangeFilter: [],
      orderBy: [],
    }, fireinfo[collection]);
    const firebaseParams = {
      collection,
      limit: params.rowsPerPage,
    };
    const memoryParams = {};
    if (doc) {
      firebaseParams.doc = doc;
    }
    if (sub_collection) {
      firebaseParams.sub_collection = sub_collection;
    }
    if (params.textFilter) {
      firebaseParams.where = Object.keys(params.textFilter).map((field) => {
        const text = params.textFilter[field]
          .replace('.', '_') // firestore doesn't support "." in map fields
          .toLowerCase();
        return [`${field}_search.${text}`, '==', true];
      });
      if (params.equalityFilter) {
        firebaseParams.where = firebaseParams.where.concat(
          Object.keys(params.equalityFilter).map(eqField => [
            eqField, '==', params.equalityFilter[eqField],
          ]),
        );
      }
      if (params.orderBy) {
        memoryParams.orderBy = params.orderBy;
      }
      if (params.rangeFilter) {
        memoryParams.rangeFilter = params.rangeFilter;
      }
    } else {
      if (params.rangeFilter) {
        const rangeField = params.rangeFilter.field;
        const {
          lower,
          lowerInclusive,
        } = params.rangeFilter;
        if (lower !== undefined) {
          const op = `>${lowerInclusive ? '=' : ''}`;
          firebaseParams.where = [].concat(firebaseParams.where || [],
            [[rangeField, op, lower]]);
          firebaseParams.orderBy = [rangeField, 'asc'];
        }
        const {
          upper,
          upperInclusive,
        } = params.rangeFilter;
        if (upper !== undefined && lower !== undefined && upper < lower) {
          throw new Error(`Invalid upper/lower bounds: ${upper}/${lower}`);
        }
        if (upper !== undefined) {
          const op = `<${upperInclusive ? '=' : ''}`;
          firebaseParams.where = [].concat(firebaseParams.where || [],
            [[rangeField, op, upper]]);
          if (collinfo.orderBy.includes(rangeField)) {
            firebaseParams.orderBy = [rangeField, 'desc'];
          }
        }
        if (params.equalityFilter) {
          const indexedParameters = Object.keys(params.equalityFilter)
            .filter(field => collinfo.equalityFilter.includes(field));
          firebaseParams.where = [].concat(firebaseParams.where || [],
            indexedParameters
              .map(fieldName => [fieldName, '==', params.equalityFilter[fieldName]]));
          const unindexedParameters = Object.keys(params.equalityFilter)
            .filter(field => !collinfo.equalityFilter.includes(field));
          if (unindexedParameters.length > 0) {
            memoryParams.equalityFilter = {};
            unindexedParameters.forEach((field) => {
              memoryParams.equalityFilter[field] = params.equalityFilter[field];
            });
          }
        }
      } else if (params.equalityFilter) {
        firebaseParams.where = [].concat(firebaseParams.where || [],
          Object.keys(params.equalityFilter)
            .map(fieldName => [fieldName, '==', params.equalityFilter[fieldName]]));
      }
      if (params.orderBy) {
        // const { indexInfo } = this;
        const orderField = params.orderBy[0];
        let missingComposite = false;
        if (params.rangeFilter && params.rangeFilter.field !== orderField) {
          missingComposite = true;
        } else {
          missingComposite = params.equalityFilter && Object.keys(params.equalityFilter).some(
            equalityField => (
              equalityField !== orderField
              && !(collinfo.equalityFilter.includes(equalityField)
                && collinfo.orderBy.includes(orderField))
            ),
          );
        }
        if (!missingComposite) {
          firebaseParams.orderBy = params.orderBy;
        } else {
          memoryParams.orderBy = params.orderBy;
        }
      }
    }
    if (params.filterExtension) {
      memoryParams.filterExtension = params.filterExtension;
    }
    if (params.sub_collection) {
      memoryParams.sub_collection = params.sub_collection;
    }
    if (params.doc) {
      memoryParams.doc = params.doc;
    }
    const plan = {
      firestore: firebaseParams,
    };
    if (Object.keys(memoryParams).length > 0) {
      firebaseParams.limit = this.memoryLimit;
      memoryParams.rowsPerPage = params.rowsPerPage;
      plan.memory = memoryParams;
    }

    return plan;
  }

  updateParams(params) {
    if (!Object.prototype.hasOwnProperty.call(params, 'rowsPerPage')) {
      throw new Error('AlpacaDataSource can not execute unlimited query.');
    }
    this._params = this._planParams(params);
    console.log('Query strategy: ', this._params);
    this._page = 0;
    this._firestoreQuery.updateParams(this._params.firestore);
    this._memoryProcessor.updateParams(this._params.memory);
    setTimeout(() => {
      this._forceFire();
    });
    return this;
  }

  updateCalculatedColumns(field_key, calc_fun) {
    this._calculatedColumns[field_key] = calc_fun;
    this._memoryProcessor.updateCalculatedColumns(field_key, calc_fun);
    /*
    setTimeout(() => {
      this._forceFire();
    });
    */
    return this;
  }

  close() {
    this._firestoreQuery.close();
    this._listener = null;
  }
}

export default QueryStrategy;
