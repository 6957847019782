import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import footerStyle from '../../../assets/jss/material-dashboard-pro-react/components/footerStyle';

function Footer({ ...props }) {
  const { classes, fluid, white } = props;

  const container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });

  const block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <div className={classes.list}>
            <div className={classes.inlineBlock}>
              <b>
                Copyright&nbsp;&copy;&nbsp;
                {moment().year()}
              </b>
              &nbsp;
              <Link to="/" className={block}>
                TRKX Inc.
              </Link>
              &nbsp;All rights reserved.
            </div>
          </div>
        </div>
        <p className={classes.right}>TRKX</p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
};

Footer.defaultProps = {
  fluid: false,
  white: false,
};

export default withStyles(footerStyle)(Footer);
