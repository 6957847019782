import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  spacer: {
    flex: '1 1 100%',
  },
  table: {
    minWidth: 1020,
  },
  emptyTitle: {
    minHeight: 20,
  },
  title: {
    flex: '0 0 auto',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progress: {
    margin: theme.spacing(2),
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
});

class TableToolbar extends PureComponent {
  render() {
    const { classes, componentName, render } = this.props;
    if (componentName) {
      return (
        <Toolbar className={classes.root}>
          {render({
            classes,
          })}
          {componentName && (
            <div className={classes.title}>
              <Typography variant="h4" id="tableTitle">
                {componentName}
              </Typography>
            </div>
          )}
          <div className={classes.spacer} />
        </Toolbar>
      );
    }
    return <div className={classes.emptyTitle} />;
  }
}

TableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  componentName: PropTypes.string,
  render: PropTypes.func,
};

TableToolbar.defaultProps = {
  componentName: '',
  render: () => {},
};

export default withStyles(toolbarStyles)(TableToolbar);
