import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const AutoSuggestInput = ({ disabled, inputProps }) => {
  const {
    inputRef = () => {}, classes, ref, title, ...other
  } = inputProps;

  return (
    <TextField
      autoComplete="false"
      disabled={disabled}
      label={title}
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
};

AutoSuggestInput.propTypes = {
  disabled: PropTypes.bool,
  inputProps: PropTypes.object.isRequired,
};

AutoSuggestInput.defaultProps = {
  disabled: false,
};

export default withStyles({})(AutoSuggestInput);
