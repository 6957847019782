import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Hidden,
  Toolbar,
} from '@material-ui/core';

// material-ui icons
import { Menu } from '@material-ui/icons';

// core components
import HeaderLinks from './HeaderLinks';
import Button from '../CustomButtons/Button';

import headerStyle from '../../../assets/jss/material-dashboard-pro-react/components/headerStyle';

// const capitalizeWord = (word) => {
//   // handles cases where numbers are passed in
//   if (+word > 0) {
//     return word;
//   }
//   if (typeof word !== 'string') {
//     return '';
//   }

//   if (word.includes('_')) {
//     return capitalizeArrayOfWords(word.split('_')); // eslint-disable-line
//   }

//   const firstLetter = word.charAt(0).toUpperCase();
//   return `${firstLetter}${word.slice(1)}`;
// };

// const capitalizeArrayOfWords = (wordsArray) => {
//   let result = '';
//   if (!Array.isArray(wordsArray)) {
//     return result;
//   }
//   wordsArray.forEach((word) => {
//     result += ` ${capitalizeWord(word)}`;
//   });
//   return result.trim();
// };

// const formatPathname = (path) => {
//   const words = path.split('/');
//   return capitalizeArrayOfWords(words);
// };

function Header({ ...props }) {
  function makeBrand() {
    let displayName;
    props.routes.map((prop) => {
      if (prop.collapse) {
        prop.views.map((view) => {
          // pathname is loads/121...
          // path is loads/:id
          if (view.path.includes('/:id')) {
            const arr = view.path.split('/:id');
            const [route] = arr;
            if (props.location.pathname.includes(route)) {
              // displayName = formatPathname(props.location.pathname);
              displayName = view.name;
            }
          }
          if (view.path === props.location.pathname) {
            displayName = view.name;
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        displayName = prop.name;
      }
      return null;
    });
    if (displayName) {
      return displayName;
    }
    return 'TRKX';
  }
  const {
    classes,
    color,
    handleDrawerToggle,
    isPublicRoute,
    rtlActive,
  } = props;
  const appBarClasses = cx({
    [` ${classes[color]}`]: color,
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            {makeBrand()}
          </Button>
        </div>
        {!isPublicRoute
          && (
            <Hidden smDown implementation="css">
              <HeaderLinks rtlActive={rtlActive} />
            </Hidden>
          )
        }
        {!isPublicRoute
          && (
            <Hidden mdUp implementation="css">
              <Button
                className={classes.appResponsive}
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <Menu />
              </Button>
            </Hidden>
          )
        }
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent']),
  handleDrawerToggle: PropTypes.func,
  isPublicRoute: PropTypes.bool,
  location: PropTypes.object,
  miniActive: PropTypes.bool,
  rtlActive: PropTypes.bool,
  routes: PropTypes.array,
  sidebarMinimize: PropTypes.func,
};

Header.defaultProps = {
  color: 'transparent',
  handleDrawerToggle: () => { },
  isPublicRoute: false,
  location: {
    hash: '#/',
    pathname: '/',
  },
  miniActive: false,
  rtlActive: false,
  routes: [],
  sidebarMinimize: () => { },
};

export default withStyles(headerStyle)(Header);
