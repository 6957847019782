import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FirestoreDocument } from 'react-firestore';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  DialogTitle,
  Tab,
  Tabs,
} from '@material-ui/core';

import UserSettingsModal from '../user_settings/index';
import Loading from './loading';

const styles = ({
  appBar: {
    paddingBottom: 15,
    boxShadow: 'none',
    background: 'transparent',
  },
});

class UserSettingsBody extends Component {
  state = {
    tab: 'details',
    dataLoaded: false,
  };

  static propTypes = {
    classes: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    id: PropTypes.string,
    renderSuccess: PropTypes.func.isRequired,
    tab: PropTypes.string,
  }

  static defaultProps = {
    classes: {},
    id: null,
    tab: 'details',
  };

  componentDidMount() {
    const { tab } = this.props;
    if (tab) {
      this.setState({ tab, dataLoaded: true });
    } else {
      this.setState({ dataLoaded: true });
    }
  }


  changeTab = (value) => {
    this.setState({
      tab: value,
    });
  }

  render() {
    const {
      tab,
      dataLoaded,
    } = this.state;

    const {
      classes,
      handleClose,
      id,
      renderSuccess,
    } = this.props;

    if (!dataLoaded) {
      return <Loading />;
    }

    if (id) {
      return (
        <FirestoreDocument
          path={`user_profiles/${id}`}
          render={({ isLoading, data }) => (isLoading ? (
            <Loading />
          ) : (
            <div>
              <DialogTitle>User Settings</DialogTitle>
              <AppBar
                classes={{ root: classes.appBar }}
                position="static"
                color="default"
              >
                <Tabs
                  id="user-settings-tabs"
                  value={tab}
                  indicatorColor="primary"
                  onChange={(e, value) => this.changeTab(value)}
                >
                  <Tab value="details" label="User Details" />
                  <Tab value="password" label="Password Setup" />
                </Tabs>
              </AppBar>
              <UserSettingsModal
                tab={tab}
                data={data}
                handleClose={handleClose}
                renderSuccess={renderSuccess}
              />
            </div>
          ))
          }
        />
      );
    }
    return (
      <UserSettingsModal
        tab={tab}
        renderSuccess={renderSuccess}
        handleClose={handleClose}
      />
    );
  }
}

export default withStyles(styles)(UserSettingsBody);
