import React from 'react';
import PropTypes from 'prop-types';

import firebase from 'firebase/app';
import 'firebase/functions';

import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Footer from '../../components/mdpr/Footer/Footer';

import pagesStyle from '../../assets/jss/material-dashboard-pro-react/layouts/pagesStyle';

import bgImage from '../../assets/img/trkx-login.jpg';
import LoginPage from '../../views/Pages/LoginPage';
import RegisterPage from '../../views/Pages/RegisterPage';

import {
  decrypt,
} from '../../utils';

class Pages extends React.Component {
  state = {
    error: false,
    errorMessage: '',
    loading: false,
    forgotPassword: false,
  };

  componentDidMount() {
    const {
      match: {
        params: {
          code,
        },
      },
    } = this.props;
    document.body.style.overflow = 'unset';

    if (code) {
      this.setState(
        {
          loading: true,
        },
        () => {
          firebase.functions().httpsCallable('autoLink/')({ c: code })
            .then((resp) => {
              if (resp.data) {
                try {
                  const data_string = decrypt(resp.data);
                  const resp_data = JSON.parse(data_string);
                  if (resp_data.key && resp_data.id) {
                    this.handleSubmit(`${resp_data.id}@trkx.com`, `${resp_data.key}`);
                  }
                } catch (err) {
                  toast.error('The link is no longer valid.');
                  this.setState({ loading: false });
                }
              }
            });
        },
      );
    }
  }

  toggleForgotPassword = () => {
    const { forgotPassword } = this.state;
    this.setState({ forgotPassword: !forgotPassword });
  };

  handleForgotPassword = (email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => this.toggleForgotPassword())
      .catch((error) => {
        // An error happened.
        console.error('err: ', error);
      });
  };

  handleSubmit = (email, password) => {
    const {
      history,
    } = this.props;

    this.setState(
      {
        loading: true,
      },
      () => {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((result) => {
            const { uid, refreshToken } = result.user;
            firebase
              .firestore()
              .collection('user_profiles')
              .doc(`${uid}`)
              .update({ refreshToken });
          })
          .then(() => {
            this.setState({
              loading: false,
            });
            history.push('/dashboard'); // navigate away from login
          })
          .catch((error) => {
            const { message } = error;
            this.setState({
              error: true,
              errorMessage: message,
              loading: false,
            });
            setTimeout(() => {
              this.setState({
                error: false,
                errorMessage: '',
              });
            }, 3000);
          });
      },
    );
  };

  render() {
    const { classes } = this.props;
    const {
      error, errorMessage, forgotPassword, loading,
    } = this.state;
    return (
      <div>
        <div className={classes.wrapper}>
          <div className={classes.fullPage} style={{ backgroundImage: `url(${bgImage})` }}>
            {!forgotPassword && (
              <LoginPage
                handleSubmit={this.handleSubmit}
                loading={loading}
                error={error}
                errorMessage={errorMessage}
                toggleForgotPassword={this.toggleForgotPassword}
              />
            )}
            {forgotPassword && (
              <RegisterPage
                handleForgotPassword={this.handleForgotPassword}
                toggleForgotPassword={this.toggleForgotPassword}
                handleSubmit={this.handleSubmit}
                loading={loading}
                error={error}
                errorMessage={errorMessage}
              />
            )}
            <Footer white />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
};

Pages.defaultProps = {
  match: {},
};

export default compose(
  withRouter,
  withStyles(pagesStyle),
)(Pages);
