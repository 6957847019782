import deepEqual from 'deep-equal';

import frozenClone from './frozenClone';

class FirestoreQuery {
  constructor(firestore) {
    this._firestore = firestore;
    this._unregister = null;
    this._listener = null;
    this._rootQuery = null;
    this._params = null;
    this.isLoading = true;
    this._page = 0;
    this._pageStack = [];
  }

  _setQuery(params) {
    let coll = this._firestore.collection(params.collection);
    if (params.doc && params.sub_collection) {
      // set sub doc and collection
      coll = coll.doc(params.doc).collection(params.sub_collection);
    }

    let query = coll;
    const { where } = params;
    if (where && where.length > 0) {
      where.forEach((c) => {
        query = query.where(c[0], c[1], c[2]);
      });
    }
    const { orderBy } = params;
    if (orderBy) {
      const field = orderBy[0];
      const order = orderBy[1].toLowerCase();
      if (order !== 'asc' && order !== 'desc') {
        throw new Error(`Invalid order for firestore: ${JSON.stringify(params.orderBy)}`);
      }
      query = query.orderBy(field, order);
    }
    if (params.limit) {
      query = query.limit(params.limit);
    }
    this._rootQuery = query;
  }

  _startQuery(query) {
    if (!this._listener || !query) {
      return;
    }
    if (this._unregister) {
      this._unregister();
    }
    this._unregister = query.onSnapshot(
      (snapshot) => {
        const docs = [];
        snapshot.forEach((doc) => {
          docs.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        this.isLoading = false;
        if (this._pageStack.length === this._page && snapshot.docs.length === this._params.limit) {
          this._pageStack.push(snapshot.docs[snapshot.docs.length - 1]);
        }
        this._listener({
          docs,
        });
      },
      (error) => {
        this.isLoading = false;
        this._listener({
          docs: [],
          error,
        });
      },
    );
    this.isLoading = true;
  }

  set listener(newListener) {
    this._listener = newListener;
    this._startQuery(this._rootQuery);
  }

  updateParams(params) {
    if (deepEqual(params, this._params)) {
      return;
    }
    this._page = 0;
    this._params = frozenClone(params);
    this._setQuery(params);
    this._startQuery(this._rootQuery);
  }

  getPage() {
    return this._page;
  }

  setPage(pageNum) {
    if (pageNum === 0) {
      this._page = 0;
      this._startQuery(this._rootQuery);
    } else if (this._pageStack.length >= pageNum) {
      this._page = pageNum;
      this._startQuery(this._rootQuery.startAfter(this._pageStack[pageNum - 1]));
    }
    return this._page;
  }

  hasNextPage() {
    return this._pageStack.length > this._page;
  }

  close() {
    if (this._unregister) {
      this._unregister();
      this._unregister = null;
    }
  }
}

export default FirestoreQuery;
