import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';

const CAStates = ({ state, handleChange, change_state }) => (
  <Select
    value={state}
    onChange={e => handleChange(change_state, e)}
    inputProps={{
      name: change_state,
      id: change_state,
    }}
  >
    <MenuItem value="">
      <em />
    </MenuItem>
    <MenuItem value="AB">Alberta</MenuItem>
    <MenuItem value="BC">British Columbia</MenuItem>
    <MenuItem value="MB">Manitoba</MenuItem>
    <MenuItem value="NB">New Brunswick</MenuItem>
    <MenuItem value="NL">Newfoundland and Labrador</MenuItem>
    <MenuItem value="NS">Nova Scotia</MenuItem>
    <MenuItem value="ON">Ontario</MenuItem>
    <MenuItem value="PE">Prince Edward Island</MenuItem>
    <MenuItem value="QC">Quebec</MenuItem>
    <MenuItem value="SK">Saskatchewan</MenuItem>
  </Select>
);

CAStates.propTypes = {
  handleChange: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  change_state: PropTypes.string.isRequired,
};

export default CAStates;
