import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import firebase from 'firebase/app';

// this won't catch async errors
export default class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      // Error path
      toast.warn('Something went wrong');
      firebase.functions().httpsCallable('reportError/')({ error, errorInfo });
      console.error('error: ', error && error.toString());
      console.error('errorInfo: ', errorInfo.componentStack);
    }
    // Normally, just render children
    return children;
  }
}
