
function comparator(field, direction) {
  const dir = direction === 'asc' ? -1 : 1;
  return (a, b) => {
    const x = a[field];
    const y = b[field];
    if (x < y) {
      return dir;
    }
    if (x === y) {
      return 0;
    }
    return -dir;
  };
}

function rangeFilter(range) {
  const {
    field,
    upper,
    upperInclusive,
    lower,
    lowerInclusive,
  } = range;
  return (doc) => {
    const value = doc[field];
    if (upper !== undefined) {
      if (upperInclusive && value === upper) {
        return true;
      }
      if (!(value < upper)) {
        return false;
      }
    }
    if (lower !== undefined) {
      if (lowerInclusive && value === lower) {
        return true;
      }
      if (!(value > lower)) {
        return false;
      }
    }
    return true;
  };
}

class MemoryProcessor {
  constructor(listener) {
    this._listener = listener;
    this._params = null;
    this._page = 0;
    this._snapLength = null;
    this._calculatedColumns = {};
  }

  updateParams(params) {
    if (!params || Object.keys(params).length === 0) {
      this._params = null;
      return;
    }
    if (!Object.prototype.hasOwnProperty.call(params, 'rowsPerPage')) {
      throw new Error('rowsPerPage required for memory processor params');
    }
    this._params = params;
  }

  set listener(listener) {
    this._listener = listener;
  }

  setPage(pageNum) {
    if (!this._params) {
      throw new Error('Unable to set page in memory processor before receiving params.');
    }
    if (pageNum === 0) {
      this._page = 0;
      return this._page;
    }
    if (!this._snapLength) {
      throw new Error('Unable to set page in memory before receiving snapshot');
    }
    if (pageNum < Math.ceil(this._snapLength / this._params.rowsPerPage)) {
      this._page = pageNum;
    }
    return this._page;
  }

  getPage() {
    return this._page;
  }

  hasNextPage() {
    return this._page + 1 < Math.ceil(this._snapLength / this._params.rowsPerPage);
  }

  updateData(data) {
    let snap = data.slice();
    snap = this.calculateColumns(snap);
    if (!this._params) {
      this._listener(snap);
      return;
    }
    const {
      equalityFilter,
      range,
      orderBy,
      filterExtension,
    } = this._params;
    if (equalityFilter) {
      snap = snap
        .filter(doc => Object.keys(equalityFilter)
          .every((eqField) => {
            const dotPos = eqField.indexOf('.');
            if (dotPos < 0) {
              return doc[eqField] === equalityFilter[eqField];
            }
            const parentEqField = eqField.substr(0, dotPos);
            const childEqField = eqField.substr(dotPos + 1, eqField.length - dotPos);
            if (doc[parentEqField] === undefined) {
              return false;
            }
            return doc[parentEqField][childEqField] === equalityFilter[eqField];
          }));
    }
    if (range) {
      snap = snap
        .filter(rangeFilter(range));
    }
    if (filterExtension) {
      snap = snap.filter(filterExtension);
    }
    if (orderBy) {
      const field = orderBy[0];
      snap = snap
        .filter(doc => doc[field] !== undefined)
        .sort(comparator(...orderBy));
    }

    const { rowsPerPage } = this._params;
    this._snapLength = snap.length;
    const maxPage = Math.ceil(this._snapLength / rowsPerPage) - 1;
    if (this._page > maxPage) {
      this._page = Math.max(0, maxPage);
    }
    const { _page } = this;
    this._listener(snap.slice(
      _page * rowsPerPage,
      (_page + 1) * rowsPerPage,
    ));
  }

  calculateColumns = (rows) => {
    const new_rows = rows.slice(0);
    Object.keys(this._calculatedColumns).forEach((field) => {
      new_rows.forEach((row, index) => {
        new_rows[index][field] = this._calculatedColumns[field](row);
      });
    });
    return new_rows;
  }

  updateCalculatedColumns = (field_key, calc_fun) => {
    this._calculatedColumns[field_key] = calc_fun;
  }
}

export default MemoryProcessor;
