
module.exports = {
  firestore: {
    branches: {
      equalityFilter: ['status'],
      orderBy: ['name', 'address'],
    },
    customers: {
      orderBy: ['id', 'name', 'type', 'mc_number', 'phoneno', 'faxno', 'email'],
      textSearch: ['name_search', 'email_search'],
    },
    truckboard_teams: {
      equalityFilter: [
        'current_status',
        'carrier_status',
      ],
      orderBy: ['availability_date'],
    },
    loads: {
      equalityFilter: [
        'agent_id',
        'is_open',
        'is_cancelled',
      ],
      orderBy: ['registration_date', 'last_delivery_date'],
    },
    user_profiles: {
      equalityFilter: ['fcmDesktopToken'],
      arrayFilter: ['roles'],
      orderBy: ['fcmDesktopToken'],
    },
  },
};
