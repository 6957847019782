import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  label: {
    color: '#000',
  },
});

const RadioButtons = ({
  radioButtons,
  checked,
  checkFunc,
  classes,
  disabled,
  label,
}) => (
  <div>
    {label && (<InputLabel>{label}</InputLabel>)}
    {radioButtons.map(radio => (
      <FormControlLabel
        key={radio.value}
        control={(
          <Radio
            checked={checked === radio.value}
            onChange={(e) => {
              checkFunc(e);
            }}
            value={radio.value}
            name={radio.name}
            aria-label={radio.name}
            disabled={disabled}
            color="primary"
          />
        )}
        label={radio.label}
        classes={classes}
      />
    ))}
  </div>
);

RadioButtons.propTypes = {
  radioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  checked: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checkFunc: PropTypes.func.isRequired,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

RadioButtons.defaultProps = {
  classes: {},
  disabled: false,
  label: '',
};

export default withStyles(styles)(RadioButtons);
