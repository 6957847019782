
// Parameter api

import frozenClone from './frozenClone';

class Params {
  constructor(collection, rowsPerPage, executor) {
    if (!collection) {
      throw new Error('collection required for alpaca data source params');
    }
    if (!rowsPerPage) {
      throw new Error('rowsPerPage required for alaca data source params');
    }
    if (!executor) {
      throw new Error('executor required for params API');
    }
    this._params = {
      collection,
      rowsPerPage,
    };
    this._executor = executor;
  }

  rangeFilter = (field) => {
    this.method = true;
    const rangeApi = Object.freeze({
      upper: (upper, upperInclusive) => {
        let prevFilter = this._params.rangeFilter;
        if (prevFilter
            && (prevFilter.field !== field
                || (prevFilter.lower !== undefined
                    && prevFilter.lower > upper))) {
          prevFilter = {};
        }
        this._params.rangeFilter = Object.assign({},
          prevFilter,
          {
            field,
            upper,
            upperInclusive: !!upperInclusive,
          });
        return rangeApi;
      },
      upperInclusive: upper => rangeApi.upper(upper, true),
      lower: (lower, lowerInclusive) => {
        let prevFilter = this._params.rangeFilter;
        if (prevFilter
          && (prevFilter.field !== field
              || (prevFilter.upper !== undefined
                  && prevFilter.upper < lower))) {
          prevFilter = {};
        }
        this._params.rangeFilter = Object.assign({},
          prevFilter,
          {
            field,
            lower,
            lowerInclusive: !!lowerInclusive,
          });
        return rangeApi;
      },
      lowerInclusive: lower => rangeApi.lower(lower, true),
      execute: () => this.execute(),
    });
    return rangeApi;
  }

  addFilterExtension(f) {
    this._params.filterExtension = f;
    return this;
  }

  doc(doc) {
    this._params.doc = doc;
    return this;
  }

  subCollection(sub_collection) {
    this._params.sub_collection = sub_collection;
    return this;
  }

  removeFilterExtension() {
    delete this._params.filterExtension;
    return this;
  }

  removeRangeFilter() {
    delete this._params.rangeFilter;
    return this;
  }

  addEqualityFilter(field, value) {
    this._params.equalityFilter = Object.assign({},
      this._params.equalityFilter,
      { [field]: value });
    return this;
  }

  addTextFilter(field, value) {
    this._params.textFilter = Object.assign({},
      this._params.textFilter,
      {
        [field]: value,
      });
    return this;
  }

  hasTextFilter(field) {
    if (this._params.textFilter === undefined || this._params.textFilter === null) {
      return false;
    }
    if (Object.keys(this._params.textFilter).indexOf(field) < 0) {
      return false;
    }
    return true;
  }

  removeTextFilter(field) {
    delete this._params.textFilter[field];
    if (Object.keys(this._params.textFilter).length === 0) {
      delete this._params.textFilter;
    }
    return this;
  }

  collection(collection) {
    this._params.collection = collection;
    return this;
  }

  get() {
    return frozenClone(this._params);
  }

  // generate an array of all possible permutations for a list of values with length maxLen
  getPermutations = (list, maxLen) => {
    const perm = list.map(val => [val]);
    const generate = (perm_arg, maxLen_arg, currLen) => {
      if (currLen === maxLen_arg) {
        return perm_arg;
      }
      for (let i = 0, len = perm_arg.length; i < len; i++) {
        const currPerm = perm_arg.shift();
        for (let k = 0; k < list.length; k++) {
          perm_arg.push(currPerm.concat(list[k]));
        }
      }
      return generate(perm_arg, maxLen_arg, currLen + 1);
    };
    return generate(perm, maxLen, 1);
  };

  removeComputedPermutationFilters(field, num_booleans) {
    const value_list = [0, 1];
    const result_array = this.getPermutations(value_list, num_booleans);
    result_array.forEach((result_row) => {
      this.removeEqualityFilter(`${field}.${result_row.join('')}`);
    });
    return this;
  }

  removeEqualityFilter(field) {
    if (this._params.equalityFilter) {
      delete this._params.equalityFilter[field];
    }
    return this;
  }

  reset(defaults) {
    this._params = {
      collection: (defaults && defaults.collection) || this._params.collection,
      rowsPerPage: (defaults && defaults.rowsPerPage) || this._params.rowsPerPage,
    };
    return this;
  }

  orderBy(order) {
    if (!Array.isArray(order)) {
      throw new Error("OrderBy expects an argument of the form ['field', 'asc']");
    }
    const field = order[0];
    const dir = order[1].toLowerCase();
    if (!(dir === 'asc' || dir === 'desc')) {
      throw new Error(`Unknown order direction ${dir}`);
    }
    this._params.orderBy = [field, dir];
    return this;
  }

  rowsPerPage(rowsPerPage) {
    this._params.rowsPerPage = rowsPerPage;
    return this;
  }

  execute() {
    this._executor(this.get());
  }
}

export default Params;
