import React, { Component } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
// import LockOutline from '@material-ui/icons/LockOutline';

// core components
import GridContainer from '../../components/mdpr/Grid/GridContainer';
import GridItem from '../../components/mdpr/Grid/GridItem';
import CustomInput from '../../components/mdpr/CustomInput/CustomInput';
import Button from '../../components/mdpr/CustomButtons/Button';
import Card from '../../components/mdpr/Card/Card';
import CardBody from '../../components/mdpr/Card/CardBody';
import CardHeader from '../../components/mdpr/Card/CardHeader';
import CardFooter from '../../components/mdpr/Card/CardFooter';
import CardText from '../../components/mdpr/Card/CardText';
import SnackbarContent from '../../components/mdpr/Snackbar/SnackbarContent';

import loginPageStyle from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';

class LoginPage extends Component {
  state = {
    cardAnimaton: 'cardHidden',
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggleForgotPassword: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    errorMessage: '',
    loading: false,
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(() => {
      this.setState({ cardAnimaton: '' });
    }, 700);
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleChange = (event) => {
    this.setState({
      [event.currentTarget.id]: event.currentTarget.value,
    });
  };

  render() {
    const {
      classes,
      error,
      errorMessage,
      toggleForgotPassword,
      handleSubmit,
      loading,
    } = this.props;
    const { email, password, cardAnimaton } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            {error ? <SnackbarContent message={errorMessage} color="rose" /> : null}
            <form>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary">
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      onChange: event => this.handleChange(event),
                      required: true,
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      type: 'password',
                      onChange: event => this.handleChange(event),
                      required: true,
                    }}
                  />
                </CardBody>
                <CardText
                  className={`${classes.forgotPassword} ${classes.textCenter}`}
                  onClick={() => toggleForgotPassword()}
                >
                  Forgot Password?
                </CardText>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="primary" simple size="lg" block disabled={loading} onClick={() => handleSubmit(email, password)}>
                    Sign In
                    {' '}
                    {loading && <CircularProgress size={12} className={classes.buttonProgress} />}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(LoginPage);
