import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
} from '@material-ui/core';

const styles = ({
  container: {
    textAlign: 'center',
    color: '#000',
    overflowY: 'unset',
  },
});

class DialogComponent extends Component {
  state = {
    processing: false,
  }

  static propTypes = {
    body: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    classes: PropTypes.object.isRequired,
    closeLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    disableConfirm: PropTypes.bool,
    handleConfirm: PropTypes.func,
    maxWidth: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    body: '',
    closeLabel: 'Close',
    confirmLabel: 'Confirm',
    disableConfirm: false,
    handleConfirm: null,
    maxWidth: 'md',
    title: '',
  };

  handleConfirmClick = () => {
    this.setState({ processing: true });
    const { handleConfirm } = this.props;
    handleConfirm();
    this.setState({ processing: false });
  }

  renderDialogButtons = () => {
    const {
      closeLabel,
      confirmLabel,
      disableConfirm,
      handleConfirm,
      onClose,
    } = this.props;
    const { processing } = this.state;

    if (processing && handleConfirm) {
      return (
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            disabled
          >
            Processing...
            <CircularProgress size={20} />
          </Button>
        </DialogActions>
      );
    }

    return (
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="default"
        >
          {closeLabel}
        </Button>
        {!processing
          && handleConfirm
          && (
            <Button
              variant="contained"
              onClick={() => this.handleConfirmClick()}
              color="primary"
              disabled={disableConfirm}
            >
              {confirmLabel}
            </Button>
          )
        }
      </DialogActions>
    );
  }

  render() {
    const {
      body,
      classes,
      maxWidth,
      onClose,
      open,
      title,
    } = this.props;
    return (
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        {title && (
          <DialogTitle align="center">
            {title}
          </DialogTitle>
        )}
        <Divider />
        <DialogContent classes={{ root: classes.container }}>
          {body}
        </DialogContent>
        {this.renderDialogButtons()}
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogComponent);
