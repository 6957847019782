import '@firebase/firestore'; // eslint-disable-line import/no-extraneous-dependencies
import 'firebase/messaging';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
// import registerServiceWorker from './registerServiceWorker'
import thunk from 'redux-thunk';
// import JssProvider from 'react-jss/lib/JssProvider';

import { FirestoreProvider } from 'react-firestore';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { createStore, compose, applyMiddleware } from 'redux';
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import reducer from './reducers/index';
import App from './App';
import AlpacaDataSource from './containers/alpacadatasource';
import { fcmWPS } from './firebase_config';
import overrides from './overrides';
import typography from './typography';

const theme = createMuiTheme({
  overrides,
  palette: {
    primary: {
      main: '#4B6F92',
    },
    secondary: {
      main: '#A83F3E',
    },
  },
  typography,
});

firebase.initializeApp(require('./firebase_config'));

const messaging = firebase.messaging();
messaging.usePublicVapidKey(fcmWPS);

const firestore = firebase.firestore();
firestore.settings({});

AlpacaDataSource.setIndexInfo(require('./indexInfo'));

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch((err) => {
      console.log('Service worker registration failed, error:', err);
    });
}

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ReduxProvider store={store}>
        <FirestoreProvider firebase={firebase}>
          <Router>
            <App />
          </Router>
        </FirestoreProvider>
      </ReduxProvider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
// registerServiceWorker()
