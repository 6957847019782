import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const OptionsContainer = ({ options }) => (
  <Paper {...options.containerProps} elevation={2} square>
    {options.children}
  </Paper>
);

OptionsContainer.propTypes = {
  options: PropTypes.object.isRequired,
};

export default withStyles({})(OptionsContainer);
