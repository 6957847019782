import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  DialogActions,
} from '@material-ui/core';

const styles = ({
  marginTop: {
    marginTop: 15,
  },
});

const SubmitButton = ({
  classes,
  closeLabel,
  confirmLabel,
  disabled,
  handleClose,
  saving,
}) => {
  if (saving) {
    return (
      <DialogActions classes={{ root: classes.marginTop }}>
        <Button
          disabled
          color="default"
          variant="contained"
        >
          Processing...
          <CircularProgress size={20} />
        </Button>
      </DialogActions>
    );
  }
  return (
    <DialogActions classes={{ root: classes.marginTop }}>
      <Button
        onClick={handleClose}
        color="default"
        variant="contained"
      >
        {closeLabel}
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={disabled}
      >
        {confirmLabel}
      </Button>
    </DialogActions>
  );
};

SubmitButton.propTypes = {
  classes: PropTypes.object.isRequired,
  closeLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  disabled: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

SubmitButton.defaultProps = {
  closeLabel: 'Cancel',
  confirmLabel: 'Submit',
  disabled: false,
};

export default withStyles(styles)(SubmitButton);
