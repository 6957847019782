const MuiTab = {
  root: {
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#4A4A4A !important',
  },
};

const overrides = {
  MuiTab,
};

export default overrides;
