import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  MenuItem,
  Select,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    borderBottom: '2px solid #4B6F92',
  },
  tableSortLabel: {
    fontSize: '13px',
    color: '#4A4A4A',
    fontWeight: 700,
  },
});

class TableHeadComponent extends Component {
  state = {
    column_meta: {},
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    controls: PropTypes.object.isRequired,
    handleTextSearch: PropTypes.func,
    removeTextSearch: PropTypes.func,
    handleOrSearch: PropTypes.func,
    searchValue: PropTypes.func.isRequired,
  };

  static defaultProps = {
    handleTextSearch: () => { },
    removeTextSearch: () => { },
    handleOrSearch: () => { },
  };

  componentDidMount = () => {
    const { controls, columns } = this.props;

    const newStateObj = {
      column_meta: {},
    };

    if (
      controls
      && controls.params
      && controls.params._params
      && controls.params._params.textFilter
    ) {
      const textFilters = controls.params._params.textFilter;
      const textFilterFields = Object.keys(textFilters);

      // permutation values
      columns.forEach((column) => {
        if (column.ms_options && textFilterFields.indexOf(column.id) >= 0) {
          newStateObj.column_meta[column.id] = { ms_values: [] };
          const boolean_array = textFilters[column.id].split('');
          boolean_array.forEach((value, index) => {
            if (parseInt(value, 10)) {
              newStateObj.column_meta[column.id].ms_values.push(column.ms_options[index].value);
            }
          });
        }
      });
    }

    if (
      controls
      && controls.params
      && controls.params._params
      && controls.params._params.equalityFilter
    ) {
      const equalityFilters = controls.params._params.equalityFilter;

      // Or Search Values
      columns.forEach((column) => {
        if (column.ms_options && column.is_ms_or) {
          column.ms_options.forEach((option) => {
            if (newStateObj.column_meta[column.id] === undefined) {
              newStateObj.column_meta[column.id] = { ms_values: [] };
            }
            const normalized_option = option.value.replace('.', '_').replace(' ', '_');
            const normalized_key = `${column.id}.${normalized_option}.true`;
            if (Object.keys(equalityFilters).indexOf(normalized_key) >= 0) {
              if (equalityFilters[normalized_key]) {
                newStateObj.column_meta[column.id].ms_values.push(option.value);
              }
            }
          });
        }
      });
    }

    this.setState(newStateObj);
  };

  createSortHandler = column => (event) => {
    const { id, sort } = column;
    const { onRequestSort } = this.props;
    if (sort) {
      onRequestSort(event, id);
    }
  };

  searchValue = (field) => {
    const { searchValue } = this.props;
    return searchValue(field);
  };

  handleMultSelectChange = column => (event) => {
    const { handleTextSearch, removeTextSearch, handleOrSearch } = this.props;
    const { column_meta } = this.state;
    const new_column_meta = Object.assign({}, column_meta);
    if (column_meta[column.id] === undefined) {
      new_column_meta[column.id] = {
        ms_values: event.target.value,
      };
    } else {
      new_column_meta[column.id].ms_values = event.target.value;
    }

    this.setState({
      column_meta: new_column_meta,
    });

    // multi select search
    const has_ms_options = (
      new_column_meta[column.id]
      && new_column_meta[column.id].ms_values
      && new_column_meta[column.id].ms_values.length > 0
    );

    if (column.is_ms_or) {
      handleOrSearch(column.id, column.ms_options, new_column_meta[column.id].ms_values);
    } else {
      let permutation_text_search = '';
      if (has_ms_options) {
        column.ms_options.forEach((option) => {
          permutation_text_search
            += new_column_meta[column.id].ms_values.indexOf(option.value) >= 0 ? '1' : '0';
        });
      }
      if (has_ms_options) {
        handleTextSearch(column.id, permutation_text_search);
      } else {
        removeTextSearch(column.id);
      }
    }
  };

  renderSortLabel = (column) => {
    const { order, orderBy, classes } = this.props;
    let arrow_direction = order;
    if (column.sort_reverse) {
      if (order === 'asc') {
        arrow_direction = 'desc';
      } else {
        arrow_direction = 'asc';
      }
    }
    return (
      <TableSortLabel
        className={classes.tableSortLabel}
        active={orderBy === column.id}
        direction={arrow_direction}
        onClick={this.createSortHandler(column)}
        hideSortIcon={!column.sort}
      >
        {column.label}
      </TableSortLabel>
    );
  };

  render() {
    const {
      order,
      orderBy,
      columns,
      classes,
    } = this.props;
    const { column_meta } = this.state;
    return (
      <TableHead className={classes.root}>
        <TableRow>
          {columns.map(
            column => (
              <TableCell
                key={`${column.id}_${column.subkey || ''}`}
                align={column.align}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                id={column.id}
              >
                <Grid container>
                  <Grid item xs={12}>
                    {column.sort && (
                      <Tooltip
                        title="Sort"
                        placement={column.align === 'right' ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                        {this.renderSortLabel(column)}
                      </Tooltip>
                    )}
                    {!column.sort && this.renderSortLabel(column)}
                  </Grid>
                  <Grid item xs={12}>
                    {column.ms_options && (
                      <Select
                        multiple
                        value={column_meta[column.id] ? column_meta[column.id].ms_values : []}
                        onChange={this.handleMultSelectChange(column)}
                      >
                        {column.ms_options.map(oitem => (
                          <MenuItem key={oitem.value} value={oitem.value}>
                            {oitem.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {column.options && (
                      <Select
                        value={this.searchValue(column.id)}
                        onChange={(event) => {
                          const { handleTextSearch } = this.props;
                          handleTextSearch(
                            column.id,
                            event.target.value,
                            column.equality_text_search,
                          );
                        }}
                      >
                        {column.options.map(oitem => (
                          <MenuItem key={oitem.value} value={oitem.value}>
                            {oitem.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {(column.text_search || column.equality_text_search) && !column.options && (
                      <TextField
                        type="search"
                        defaultValue={this.searchValue(column.id)}
                        InputProps={{
                          onKeyDown: (event) => {
                            if (event.keyCode === 13) {
                              const { handleTextSearch } = this.props;
                              handleTextSearch(
                                column.id,
                                event.target.value,
                                column.equality_text_search,
                              );
                            }
                            return true;
                          },
                          onChange: (event) => {
                            const { removeTextSearch } = this.props;
                            if (event.target.value === '') {
                              removeTextSearch(column.id);
                            }
                          },
                        }}
                      />
                    )}
                    {!column.text_search && <div style={{ height: 32 }}>&nbsp;</div>}
                  </Grid>
                </Grid>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default withStyles(styles)(TableHeadComponent);
