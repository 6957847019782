import React from 'react';
import {
  Grid,
  FormControl,
  Input,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { containerSpacing, role_names } from '../../common';

const styles = () => ({
  border: {
    border: '1px #000 solid',
    background: '#fff',
    padding: 10,
  },
  roleControl: {
    marginTop: 16,
    maxWidth: '100%',
    minWidth: '100%',
    textAlign: 'left',
  },
  topPadding: {
    paddingTop: '8px !important',
  },
});

const PushNotificationBody = ({
  classes,
  handleChange,
  message,
  roles,
  user_id,
}) => (
  <Grid container spacing={containerSpacing}>
    <Grid item xs={12} md={8}>
      <FormControl required className={classes.roleControl}>
        <InputLabel htmlFor="select-multiple">Roles</InputLabel>
        <Select
          multiple
          value={roles}
          onChange={e => handleChange('roles', e)}
          input={<Input id="select-multiple" />}
        >
          {role_names.map(role_item => (
            <MenuItem key={role_item.value} value={role_item.value}>
              {role_item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField
        label="User ID"
        fullWidth
        value={user_id || ''}
        onChange={e => handleChange('user_id', e)}
        margin="normal"
        required
      />
    </Grid>
    <Grid
      item
      xs={12}
      classes={{
        root: classes.topPadding,
      }}
    >
      <Typography
        gutterBottom
        variant="h6"
        align="left"
      >
        Message
      </Typography>
      <InputBase
        value={message || ''}
        inputProps={{ 'aria-label': 'naked' }}
        onChange={e => handleChange('message', e)}
        fullWidth
        multiline
        margin="dense"
        rows={4}
        classes={{
          root: classes.border,
        }}
      />
    </Grid>
  </Grid>
);

PushNotificationBody.propTypes = {
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  roles: PropTypes.array,
  user_id: PropTypes.string,
};

PushNotificationBody.defaultProps = {
  message: '',
  roles: [],
  user_id: '',
};

export default withStyles(styles)(PushNotificationBody);
