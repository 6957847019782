import React, { Component } from 'react';
import { withFirestore } from 'react-firestore';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import LoadsTableBody from './loads_table_body';
import ADT from '../adt';
import AlpacaDataSource from '../../containers/alpacadatasource';
import { openDocumentControllerModal } from '../../actions';

const styles = () => ({
  root: {
    marginTop: 10,
  },
  formControl: {
    width: '100%',
  },
});
class LoadsPastDue extends Component {
  state = {
    search_load_type: 'overdue',
  }

  static propTypes = {
    // handleEditCarrier: PropTypes.func.isRequired,
    current_user: PropTypes.object.isRequired,
    current_customer_id: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  renderActiveTable = (statusState, rows) => {
    const { openModal } = this.props;
    if (statusState === 'active') {
      return (
        <LoadsTableBody
          data={rows}
          openModal={openModal}
        />
      );
    }
    return (
      <LoadsTableBody
        data={rows}
        openModal={openModal}
      />
    );
  };

  render() {
    const { current_user, current_customer_id } = this.props;
    const { search_load_type } = this.state;
    if (current_user === undefined) {
      return <div />;
    }

    const defaultEqualityFilters = {};

    return (
      <div>
        <AlpacaDataSource
          equalityFilter={defaultEqualityFilters}
          collection={`views/customer/public/${current_customer_id}/loads_overdue`}
          rowsPerPage={100}
        >
          {(data, controls) => {
            const columns = [
              {
                id: 'referenceno',
                label: 'Invoice Number',
                text_search: true,
                sort: true,
              },
              {
                id: 'display_id',
                label: 'Load ID',
                text_search: true,
                sort: true,
              },
              {
                id: 'mc_authority',
                label: 'Carrier Name',
                text_search: true,
                sort: true,
              },
              {
                id: 'customer_due_date',
                label: 'Aging',
                sort: true,
                sort_reverse: true,
                subkey: '1',
              },
              {
                id: 'customer_due_date',
                label: 'Due Date',
                sort: true,
                subkey: '2',
              },
              {
                id: 'transaction_data.customer_debt',
                label: 'Amount',
                sort: true,
              },
              {
                id: 'customer_feedback.payment_status',
                label: 'Status',
              },
              {
                id: 'action',
                label: 'Actions',
              },
            ];
            return (
              <div>
                <ADT columns={columns} controls={controls} data={data}>
                  {this.renderActiveTable(search_load_type, data.rows)}
                </ADT>
              </div>
            );
          }}
        </AlpacaDataSource>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  current_user: state.user.user,
  current_customer_id: state.user.customer_id,
});

const LoadsPastDueClass = withFirestore(LoadsPastDue);
export default compose(
  connect(
    mapStateToProps,
    {
      openModal: openDocumentControllerModal,
    },
  ),
  withStyles(styles),
)(LoadsPastDueClass);
