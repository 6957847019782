import React from 'react';

import {
  OPEN_DOCUMENT_CONTROLLER_MODAL,
  CLOSE_DOCUMENT_CONTROLLER_MODAL,
  OPEN_SECONDARY_DOCUMENT_CONTROLLER_MODAL,
  CLOSE_SECONDARY_DOCUMENT_CONTROLLER_MODAL,
} from '../constants';

const initialState = {
  open: false,
  openSecondary: false,
  children: <span />,
  secondaryChildren: <span />,
};

const documentControllerModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DOCUMENT_CONTROLLER_MODAL:
      return {
        ...state,
        open: true,
        children: action.children,
      };
    case CLOSE_DOCUMENT_CONTROLLER_MODAL:
      return {
        ...initialState,
      };
    case OPEN_SECONDARY_DOCUMENT_CONTROLLER_MODAL:
      return {
        ...state,
        openSecondary: true,
        secondaryChildren: action.secondaryChildren,
      };
    case CLOSE_SECONDARY_DOCUMENT_CONTROLLER_MODAL:
      return {
        ...state,
        openSecondary: false,
        secondaryChildren: <span />,
      };
    default:
      return state;
  }
};

export default documentControllerModalReducer;
