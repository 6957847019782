const borderOnHover = {
  borderBottom: '2px #A83F3E solid',
};

const regularBorder = '2px transparent solid';

export const rowStyle = {
  even: {
    backgroundColor: '#F1F8FF',
    '&:hover': borderOnHover,
    borderBottom: regularBorder,
  },
  cursor: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: 'hotpink',
    },
  },
  root: {
    '&:hover': borderOnHover,
    borderBottom: regularBorder,
  },
  border: {
    '&:hover': borderOnHover,
    borderBottom: '2px solid #CFCFCD',
  },
};

export const even = (index, classes) => ({
  root: index % 2 === 0 ? classes.even : classes.root,
});
