import React from 'react';
import { withFirestore } from 'react-firestore';
import PropTypes from 'prop-types';
import QueryStrategy from './query-strategy';
import Params from './params';

let indexInfo = {};

class AlpacaDataSource extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    firestore: PropTypes.object.isRequired,
    collection: PropTypes.string.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    orderBy: PropTypes.array,
    equalityFilter: PropTypes.object,
    rangeFilter: PropTypes.object,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: null,
    equalityFilter: null,
    orderBy: null,
    rangeFilter: null,
  };

  static setIndexInfo = (newInfo) => {
    indexInfo = newInfo;
  };

  constructor(props) {
    super(props);
    const {
      collection,
      firestore,
      rowsPerPage,
      disabled,
    } = props;
    this._firestore = firestore;
    this._controls = {
      params: new Params(collection, rowsPerPage, this.onParamChange),
      disable: this._disable,
      setPage: page => this._queryStrategy.setPage(page),
      setCalculatedColumn: this._setCalculatedColumn,
      refresh: this._refresh,
    };
    if (!disabled) {
      this._enable();
    }
    this.setPropParams();
    const initialParams = this._controls.params.get();
    this.state = {
      data: {
        isLoading: this._isLoading(),
        params: initialParams,
        rows: [],
        disabled,
        page: 0,
      },
    };
  }

  componentDidMount() {
    if (this._queryStrategy) {
      this._queryStrategy.updateParams(this._controls.params.get());
    }
  }

  // componentDidUpdate() {
  //   this.setPropParams();
  //   this._queryStrategy.updateParams(this._controls.params.get());
  // }

  componentWillUnmount() {
    this._disable();
  }

  onParamChange = (params) => {
    this._enable();
    this._queryStrategy.updateParams(params);
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        disabled: false,
        params: this._controls.params.get(),
      },
    });
  }

  onSnapshot = (rows) => {
    this.setState({
      data: {
        isLoading: this._queryStrategy.isLoading,
        params: this._controls.params.get(),
        rows,
        disabled: false,
        page: this._queryStrategy.getPage(),
        hasNextPage: this._queryStrategy.hasNextPage(),
      },
    });
  }

  setPropParams() {
    const {
      // collection,
      // rowsPerPage,
      equalityFilter,
      orderBy,
      rangeFilter,
    } = this.props;
    const { params } = this._controls;
    // params
    //   .collection(collection)
    //   .rowsPerPage(rowsPerPage);
    if (equalityFilter) {
      Object.keys(equalityFilter).forEach((field) => {
        params.addEqualityFilter(field, equalityFilter[field]);
      });
    }
    if (rangeFilter) {
      Object.keys(rangeFilter).forEach((field) => {
        Object.keys(rangeFilter[field]).forEach((range) => {
          params
            .rangeFilter(range)[field](rangeFilter[field][range]);
        });
      });
    }
    if (orderBy) {
      params.orderBy(orderBy);
    }
  }

  _setCalculatedColumn = (field_key, calc_fun) => {
    if (this._queryStrategy) {
      this._queryStrategy.updateCalculatedColumns(field_key, calc_fun);
    }
  }

  _refresh = () => {
    const {
      collection,
      rowsPerPage,
    } = this.props;
    this._controls = {
      params: new Params(collection, rowsPerPage, this.onParamChange),
      disable: this._disable,
      setPage: page => this._queryStrategy.setPage(page),
      setCalculatedColumn: this._setCalculatedColumn,
      refresh: this._refresh,
    };
    if (this._queryStrategy) {
      console.log(this._controls.params.get());
      this._queryStrategy.updateParams(this._controls.params.get());
    }
  }

  _disable = () => {
    if (this._queryStrategy) {
      this._queryStrategy.close();
    }
    this._queryStrategy = null;
    this.setState({
      data: {
        isLoading: this._isLoading(),
        params: this._controls.params.get(),
        rows: [],
        disabled: true,
      },
    });
  }

  _isLoading() {
    if (this._queryStrategy) {
      return this._queryStrategy.isLoading;
    }
    return false;
  }

  _enable() {
    if (!this._queryStrategy) {
      this._queryStrategy = new QueryStrategy(this._firestore, indexInfo, this.onSnapshot);
    }
  }

  render() {
    const { children } = this.props;
    const { data } = this.state;
    return children(data, Object.assign({}, this._controls), this);
  }
}

export default withFirestore(AlpacaDataSource);
