import moment from 'moment-timezone';
import firebase from 'firebase/app';
import crypto from 'crypto';

export const dateToString = (date) => {
  if (moment.isMoment(date)) {
    return date.toISOString();
  }
  return date;
};

export const isValue = (e, value) => e
  && e.target
  && (e.target.value === value || (e.target.parentNode && e.target.parentNode.value === value));

export const formatDate = (date = '') => {
  if (date && !date.toDate) {
    return moment(date).calendar();
  }
  if (date && date.toDate) {
    return moment(date.toDate()).calendar();
  }
  return date;
};

// Lower Case
export const formatLowerCase = (raw_str) => {
  if (!raw_str || typeof raw_str !== 'string') {
    return '';
  }
  return raw_str.toLowerCase();
};

// Title Case
export const formatTitleCase = (raw_str) => {
  const str = raw_str || '';
  return str.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
};

export const formatName = (raw_str) => {
  if (!raw_str || typeof raw_str !== 'string') {
    return '';
  }
  const pieces = raw_str.trim().split(' ');
  const new_pieces = [];
  const exempt_strings = ['LLC'];
  pieces.forEach((piece) => {
    if (piece) {
      if (exempt_strings.includes(piece)) {
        new_pieces.push(piece);
      } else {
        new_pieces.push(formatTitleCase(piece));
      }
    }
  });
  return new_pieces.join(' ');
};

// Dollar Sign, commas and period
export const formatCurrency = (raw_num) => {
  const num = raw_num || 0;
  if (num > -0.01 && num < 0.01) {
    return '$0.00';
  }
  const formatted_num = parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return `$${formatted_num}`;
};

// Add commas
export const formatNumber = (raw_num) => {
  const num = raw_num || 0;
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const calendarFormat = (date = '') => {
  if (moment.isMoment(date)) {
    return date.format('YYYY-MM-DD');
  }
  if (date && date.seconds) {
    return moment(date.seconds * 1000).format('YYYY-MM-DD');
  }
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }
  return moment(moment().toISOString()).format('YYYY-MM-DD');
};

export const formatDateToString = (date = '', date_format = 'YYYY-MM-DD', utc_to_local = false) => {
  if (!date) {
    return '';
  }

  let moment_obj = date;
  if (!moment.isMoment(moment_obj)) {
    if (date && date.seconds) {
      moment_obj = moment(date.seconds * 1000);
    } else if (date) {
      moment_obj = moment(date);
    } else {
      moment_obj = moment(moment().toISOString());
    }
  }

  if (utc_to_local) {
    return moment_obj.local().format(date_format);
  }

  return moment_obj.format(date_format);
};

export const dateToFBTimestamp = (date) => {
  const date_obj = new Date(formatDateToString(date));
  return firebase.firestore.Timestamp.fromDate(date_obj);
};

export const telephoneMask = (str) => {
  if (String(str).length === 0 || !str || str === 'null') {
    return '';
  }
  return `(${str.substr(0, 3)}) ${str.substr(3, 3)}-${str.substr(6, 4)}`;
};

export const getName = (user) => {
  if (typeof user !== 'object' || user === null) {
    return 'TRKX';
  }
  const { firstname, username } = user;
  let name = firstname;
  if (!name && username) {
    name = username;
  }
  if (!name) {
    name = 'TRKX';
  }
  return name;
};

export const normalizeSearch = value => String(value)
    .replace('.', '_')
    .toLowerCase();

export const handleCollectionSearch = (
  value,
  firestore,
  collection,
  searchProps,
  withTermination = true,
  withInactive = false,
) => {
  const normalized_word = normalizeSearch(value);
  const wordParams = [];
  const numParams = [];
  const arr = [];
  let query = firestore.collection(collection);
  let coll = firestore.collection(collection);
  if (withTermination) {
    query = query.where('terminated', '==', false);
    coll = coll.where('terminated', '==', false);
  }
  if (withInactive) {
    query = query.where('status', '==', 'active');
    coll = coll.where('status', '==', 'active');
  }

  const [firstSearchParam, secondSearchParam] = searchProps;
  wordParams.push([`${firstSearchParam}.${normalized_word}`, '==', true]);
  if (wordParams && wordParams.length > 0) {
    wordParams.forEach((c) => {
      query = query.where(c[0], c[1], c[2]);
    });
  }
  query = query
    .limit(5)
    .get()
    .then(res => res.docs)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
        return arr;
      });
    });
  if (!Number.isNaN(value) && secondSearchParam) {
    numParams.push([`${secondSearchParam}.${normalized_word}`, '==', true]);
    if (numParams && numParams.length > 0) {
      numParams.forEach((c) => {
        coll = coll.where(c[0], c[1], c[2]);
      });
    }
    coll = coll
      .limit(5)
      .get()
      .then(res => res.docs)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
          return arr;
        });
      });
  }
  return Promise.all([query, coll]).then(() => arr);
};

export const reduceObjectKeys = (included_keys, objsrc) => {
  const obj = {};
  if (typeof objsrc !== 'object') {
    return obj;
  }
  if (!Array.isArray(included_keys)) {
    return obj;
  }
  included_keys.forEach((key) => {
    if (objsrc[key] !== undefined) {
      obj[key] = objsrc[key];
    }
  });
  return obj;
};

export const checkPermissions = async (user, firestore) => {
  const roles = firestore.collection('roles');
  const ui_views = firestore.collection('ui_views');

  // create hash map to filter out duplicate ui_views and insert/delete user permissions
  const userViewMap = new Map();
  // hash map just for role based ui views, in order to distinguish overridden roles
  const roleViewMap = new Map();

  if (user.roles && user.roles.length) {
    await Promise.all(
      user.roles.map(async role_item => roles
          .where('slug', '==', role_item)
          .get()
          .then((role_results) => {
            role_results.forEach((role_doc) => {
              const permissions = role_doc.data().ui_view_permissions;
              permissions.forEach((perm) => {
                if (!userViewMap.has(perm.id)) {
                  userViewMap.set(perm.id, perm);
                  roleViewMap.set(perm.id, perm);
                }
              });
            });
          })),
    );
  } else {
    console.warn('User profile has no roles: ', user.displayName);
  }

  // check to see if user has any overridden roles.
  // based on the value (boolean), add or remove the ui_view from viewMap
  if (user.roles_overridden && Object.keys(user.roles_overridden)) {
    const ui_overrides = Object.entries(user.roles_overridden);
    await Promise.all(
      ui_overrides.map(async ([viewId, value]) => {
        if (value && !userViewMap.has(viewId)) {
          return ui_views
            .doc(`${viewId}`)
            .get()
            .then((view) => {
              const view_data = {
                id: view.data().id,
                slug: view.data().slug,
              };
              userViewMap.set(view_data.id, view_data);
            });
        }

        if (!value) {
          return userViewMap.delete(viewId);
        }

        return null;
      }),
    );
  }

  return { userViewMap, roleViewMap };
};

export const convertTimestampToString = (ts) => {
  let date = ts;
  let time = ts;
  if (typeof ts === 'string' && ts.toLowerCase().includes('z')) {
    date = moment(ts).format('MM/DD/YY');
    time = moment(ts).format('h:mm A');
  }
  if (typeof ts === 'object') {
    if (ts.seconds) {
      date = moment(ts.seconds * 1000)
        .local()
        .format('MM/DD/YY');

      time = moment(ts.seconds * 1000)
        .local()
        .format('h:mm A');
    } else {
      date = moment(ts)
        .local()
        .format('MM/DD/YY');

      time = moment(ts)
        .local()
        .format('h:mm A');
    }
  }

  return [date, time];
};

export const displayMostRecentComment = (arr) => {
  if (!Array.isArray(arr)) {
    return '';
  }
  const [first] = arr;
  return first ? `${first.username} (${new Date(first.timestamp).toLocaleString()}) - ${first.content}` : '';
};

export const displayMostRecentHistoryEvent = (arr) => {
  if (!Array.isArray(arr)) {
    return '';
  }
  const [first] = arr;
  const ts_pieces = convertTimestampToString(first.timestamp);
  return first ? `${first.username} (${ts_pieces[0]} ${ts_pieces[1]}) - ${first.content}` : '';
};

export const convertToMoment = (time) => {
  if (!time) {
    return null;
  }

  if (typeof time === 'string') {
    return moment(time);
  }

  if (time.seconds !== undefined) { // firebase timestamp
    return moment.unix(time.seconds);
  }

  return moment(`${time}`);
};

export const calculateAging = (age_date) => {
  const age_moment = convertToMoment(age_date);
  const midnight = moment().tz('America/Los_Angeles').endOf('day');
  if (age_moment && age_moment.isValid()) {
    return midnight.diff(age_moment, 'days');
  }
  return -1;
};

const algorithm = 'aes-256-ctr';
const key = Buffer.from('5468576D5A7134743677397A24432646294A404E635266556A586E3272357538', 'hex');
const iv = Buffer.from('b0db015dda045a99ebc5adf8067eaedf', 'hex');

export const encrypt = (text = null) => {
  const cipher = crypto.createCipheriv(algorithm, key, iv);
  let crypted = cipher.update(text, 'utf8', 'hex');
  crypted += cipher.final('hex');
  return crypted;
};

export const decrypt = (text = null) => {
  const decipher = crypto.createCipheriv(algorithm, key, iv);
  let dec = decipher.update(text, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return dec;
};
