import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { containerSpacing } from '../../common';

const styles = ({
  center: {
    textAlign: 'center',
    color: '#000',
  },
  container: {
    overflowY: 'inherit',
  },
  text: {
    textAlign: 'center',
    paddingTop: 50,
  },
});

class ValidatePWModal extends Component {
  state = {
    email: '',
    password: '',
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleValidatePassword: PropTypes.func.isRequired,
  };

  handleChange = (name, event) => {
    if (event && event.target && event.target.value) {
      this.setState({ [name]: event.target.value });
    } else {
      this.setState({ [name]: '' });
    }
  };

  submitPassword = () => {
    const { handleValidatePassword } = this.props;
    const { email, password } = this.state;
    handleValidatePassword(email, password);
  }

  renderTextField = (label, val, change, md, type = 'password') => (
    <Grid item xs={12} md={md}>
      <TextField
        label={label}
        fullWidth
        value={val || ''}
        onChange={e => this.handleChange(change, e)}
        margin="normal"
        required
        type={type}
      />
    </Grid>
  );

  render() {
    const {
      classes,
    } = this.props;
    const { email, password } = this.state;
    const isDisabled = email.length === 0 || password.length === 0;

    return (
      <DialogContent classes={{ root: classes.container }}>
        <DialogTitle align="center">
          Confirm User Details
        </DialogTitle>
        <Divider />
        <Grid
          alignContent="center"
          alignItems="center"
          justify="center"
          container
          spacing={containerSpacing}
          classes={{ root: classes.text }}
        >
          <Grid item xs={12}>
            <Typography>
              Please confirm your existing password before updating your password
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {this.renderTextField('Email', email, 'email', 12, 'text')}
          </Grid>
          <Grid item xs={6}>
            {this.renderTextField('Old Password', password, 'password', 12)}
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            variant="contained"
            onClick={this.submitPassword}
            color="primary"
            disabled={isDisabled}
          >
            Agree
          </Button>
        </DialogActions>
      </DialogContent>
    );
  }
}

export default withStyles(styles)(ValidatePWModal);
