import firebase from 'firebase/app';
import 'firebase/firestore';
import { checkPermissions } from '../utils';

import {
  SET_AUTH_USER,
  SET_USER,
} from '../constants';

// export const SET_MANAGER_ID = 'SET_MANAGER_ID'

// export const setManagerId = manager_id => ({
//   type: SET_MANAGER_ID  ,
//   manager_id,
// })

let userProfileUnregister = null;

async function uiViewPermissions(user) {
  const { userViewMap } = await checkPermissions(user, firebase.firestore());
  const ui_view_permissions = [];

  userViewMap.forEach(view => ui_view_permissions.push(view));

  return ui_view_permissions;
}

function getCustomerIds(user_id) {
  return firebase
    .firestore()
    .collection('user_profiles')
    .doc(user_id)
    .collection('customer_access')
    .get()
    .then(snapshot => Promise.resolve(snapshot.docs.map(mi => mi.id)));
}

function clearUser(dispatch) {
  dispatch({
    type: SET_USER,
    user: null,
    ui_view_permissions: [],
  });
}

const setAuthUser = authUser => (dispatch) => {
  if (userProfileUnregister) {
    userProfileUnregister();
    userProfileUnregister = null;
  }
  dispatch({
    type: SET_AUTH_USER,
    authuser: authUser,
  });
  clearUser(dispatch);
  if (!authUser) {
    return;
  }
  userProfileUnregister = firebase
    .firestore()
    .collection('user_profiles')
    .doc(authUser.uid)
    .onSnapshot(async (doc) => {
      if (!doc.exists) {
        console.warn('User profile does not exist');
        clearUser(dispatch);
        return;
      }
      const user_data = doc.data();
      const required_fields = [
        'displayName',
        'firstname',
        'lastname',
        'roles',
        'status',
        'email',
        'username',
      ];
      const user = {
        uid: authUser.uid,
      };
      required_fields.forEach((required_field) => {
        user[required_field] = user_data[required_field];
      });
      if (typeof user.photoURL === 'undefined' && authUser.photoURL) {
        user.photoURL = authUser.photoURL;
      }
      const customer_ids = await getCustomerIds(authUser.uid);
      const ui_view_permissions = await uiViewPermissions(user);
      dispatch({
        type: SET_USER,
        user,
        ui_view_permissions,
        customer_id: customer_ids[0],
      });
    });
};

export default setAuthUser;

// export const login = (username, password)  => {
//     return dispatch => {
//         dispatch(setManagerId(username))
//         localStore.storeManagerID(username)
//         managerLogin(username, password)
//             .then(({code, data}) => {
//               if (code === 201) {
//                 localStore.storeManagerSession(data.employee_session)
//                 dispatch(setManagerSession(data.employee_session))
//               } else {
//                 dispatch(setInvalidLogin)
//               }
//             })
//     }
// }

// export const startup = () => {
//   return dispatch => {
//     localStore.getManagerID()
//     .then(manager_id => {
//       localStore.getManagerSession()
//       .then(manager_session => {
//         dispatch(setManagerId(manager_id))
//         dispatch(setManagerSession(manager_session))
//         dispatch(setStarted)
//       })
//     })
//   }
// }
