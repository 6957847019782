// Provide firebase server time, either on demand or on a callback tick

import { Component } from 'react';
import PropTypes from 'prop-types';

import firebase from 'firebase/app';
import 'firebase/database';

const serverTimeOffset = () => {
  const offsetRef = firebase.database().ref('.info/serverTimeOffset');
  return new Promise((resolve) => {
    offsetRef.on('value', (snap) => {
      resolve(snap.val());
    });
  });
};

const serverTimeFromOffset = offset => offset + new Date().getTime();

class ServerTime extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    tickInterval: PropTypes.number,
    onTick: PropTypes.func,
  };

  static defaultProps = {
    tickInterval: null,
    onTick: () => {},
  };

  static serverTime() {
    return serverTimeOffset()
      .then(serverTimeFromOffset);
  }

  state = {
    isLoading: true,
    serverTime: undefined,
  }

  interval = null;

  componentDidMount() {
    const {
      tickInterval,
    } = this.props;
    const asyncOffset = serverTimeOffset();
    asyncOffset.then((offset) => {
      this.updateTime(offset);
      if (tickInterval) {
        this.interval = setInterval(() => {
          this.updateTime(offset);
        }, tickInterval);
      }
    });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  updateTime = (offset) => {
    const { onTick } = this.props;
    const serverTime = serverTimeFromOffset(offset);
    this.setState({
      isLoading: false,
      serverTime,
    });
    onTick(serverTime, this);
  }

  render() {
    const {
      children,
    } = this.props;
    const {
      isLoading,
      serverTime,
    } = this.state;
    return children(isLoading, serverTime, this);
  }
}

export default ServerTime;
