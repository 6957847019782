import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withFirestore } from 'react-firestore';
import firebase from 'firebase/app';
import { compose } from 'recompose';
import { connect } from 'react-redux';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { toast } from 'react-toastify';

// core components
import Header from '../components/mdpr/Header/Header';
import Footer from '../components/mdpr/Footer/Footer';
import Sidebar from '../components/mdpr/Sidebar/Sidebar';
import {
  closeDocumentControllerModal,
} from '../actions';

import dashboardRoutes from '../routes/dashboard';

import appStyle from '../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle';

import logo from '../assets/img/logo.png';

let ps;

// wrapping/composing
const WrappedRoute = ({ component: Comp, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Comp
        permissions={rest.permissions}
        handleOpenActionModal={rest.handleOpenActionModal}
        {...props}
      />
    )}
  />
);

WrappedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

class Dashboard extends Component {
  state = {
    action: '',
    action_loading: false,
    collection_name: '',
    id: '',
    mobileOpen: false,
    miniActive: false,
    name: '',
    open: false,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    firestore: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    ui_view_permissions: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
  };

  async componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);

    const {
      closeModal,
      user: { uid, notification_topics },
    } = this.props;

    const messaging = firebase.messaging();
    // const subscribedTopics = Object.keys(notification_topics)
    //   .filter(topic => (
    //     notification_topics[topic] === true
    //   ));

    messaging
      .requestPermission()
      .then(async () => {
        const token = await messaging.getToken();
        this.assignTokenToUser(token);

        if (notification_topics) {
          // this.subscribeToNotifications(token, subscribedTopics);
        }
      })
      .catch(err => console.log('Unable to get permission to notify.', err));

    messaging.onTokenRefresh(() => {
      messaging
        .getToken()
        .then((refreshedToken) => {
          console.log('Token refreshed.');
          this.assignTokenToUser(refreshedToken);
          // Indicate that the new Instance ID token has not yet been sent to the
          // app server.
          // setTokenSentToServer(false);
          // Send Instance ID token to app server.
          // sendTokenToServer(refreshedToken);
          // ...
          // this.subscribeToNotifications(refreshedToken, subscribedTopics);
        })
        .catch((err) => {
          console.log('Unable to retrieve refreshed token ', err);
          // showToken('Unable to retrieve refreshed token ', err);
        });
    });

    messaging
      .onMessage((message) => {
        const { data: { title, body } } = message;
        if (
          message
          && message.data
        ) {
          toast.success(`${title}: ${body}`);
        }
      });

    firebase
      .firestore()
      .collection('user_profiles')
      .doc(`${uid}`)
      .onSnapshot((doc) => {
        const newRefreshToken = doc.data().refreshToken;
        const usersRefreshToken = firebase.auth().currentUser.refreshToken;
        if (usersRefreshToken !== newRefreshToken) {
          closeModal();
          toast.warn('Another user has logged in with the same credentials');
          firebase.auth().signOut();
        }
      });
  }

  componentDidUpdate(e) {
    const { mobileOpen } = this.state;
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.scrollTop = 0;
      if (mobileOpen) {
        // TODO: refactor away from this implementation
        this.setState({ mobileOpen: false }); // eslint-disable-line
      }
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }

  getRoute() {
    const {
      location: { pathname },
    } = this.props;
    return pathname !== '/maps/full-screen-maps';
  }

  assignTokenToUser = (token) => {
    const { currentUser } = firebase.auth();
    firebase
      .firestore()
      .collection('user_profiles')
      .doc(`${currentUser.uid}`)
      .set(
        {
          fcmDesktopToken: token,
        },
        { merge: true },
      )
      .catch((err) => {
        console.log('error updating token: ', err);
      });
  }

  subscribeToNotifications = (token, topics) => {
    const manageSubscriptions = firebase.functions().httpsCallable('manageSubscriptions');
    manageSubscriptions({
      token,
      topics,
      action: 'subscribe',
    })
      .then(() => {
        console.log('successfully subscribed to topics');
      })
      .catch((err) => {
        console.log('error in subscribing to topics', err);
      });
  }

  renderRoutesConditionally = (property) => {
    const { ui_view_permissions } = this.props;
    return (
      <WrappedRoute
        component={property.component}
        exact={Boolean(property.exact)}
        strict={Boolean(property.exact)}
        handleOpenActionModal={this.handleOpenActionModal}
        key={property.path}
        path={property.path}
        permissions={ui_view_permissions}
      />
    );
  };

  renderSwitch = () => (
    <Switch>
      {dashboardRoutes.map((prop) => {
        if (prop.redirect) {
          return <Redirect from={prop.path} to={prop.pathTo} key={prop.path} />;
        }
        if (prop.collapse) {
          return prop.views.map(property => this.renderRoutesConditionally(property));
        }
        return this.renderRoutesConditionally(prop);
      })}
    </Switch>
  );

  handleDrawerToggle = () => {
    const { mobileOpen } = this.state;
    this.setState({ mobileOpen: !mobileOpen });
  };

  sidebarMinimize = () => {
    const { miniActive } = this.state;
    this.setState({ miniActive: !miniActive });
  };

  handleOpenActionModal = (id, name, collection_name, action) => {
    this.setState({
      open: true,
      id,
      collection_name,
      name,
      action,
    });
  };

  handleCloseActionModal = () => {
    this.setState({
      action: '',
      action_loading: false,
      collection_name: '',
      id: '',
      name: '',
      open: false,
    });
  };

  handleAction = () => {
    const { id, collection_name, action } = this.state;
    const { firestore } = this.props;
    this.setState({
      action_loading: true,
    });
    firestore
      .collection(collection_name)
      .doc(`${id}`)
      .set(
        {
          terminated: action === 'terminate',
        },
        {
          merge: true,
        },
      )
      .then(() => this.handleCloseActionModal());
  };

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  renderTerminateModal = () => {
    const {
      action,
      action_loading,
      name,
      open,
    } = this.state;
    const { classes } = this.props;
    return (
      <Dialog
        maxWidth="md"
        open={open}
        onClose={this.handleCloseActionModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {action === 'terminate' ? 'Delete' : 'Activate'}
        </DialogTitle>
        <DialogContent classes={{ root: classes.overflow }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to &nbsp;
            {action}
            &nbsp;
            {name}
            ?
          </DialogContentText>
        </DialogContent>
        {!action_loading && (
          <DialogActions>
            <Button onClick={this.handleCloseActionModal} color="default" variant="contained">
              Disagree
            </Button>
            <Button onClick={this.handleAction} color="primary" variant="contained" autoFocus>
              Agree
            </Button>
          </DialogActions>
        )}
        {action_loading && (
          <DialogActions>
            <Button disabled color="default" variant="contained">
              Processing...
              <CircularProgress size={20} />
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  };

  render() {
    const { classes, ...rest } = this.props;
    const { miniActive, mobileOpen } = this.state;
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    })}`;
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={dashboardRoutes}
          logoText="Creative Tim"
          logo={logo}
          handleDrawerToggle={this.handleDrawerToggle}
          open={mobileOpen}
          color="trkx"
          bgColor="black"
          miniActive={miniActive}
          {...rest}
        />
        <div
          className={mainPanel}
          ref={(main) => {
            this.mainPanel = main;
          }}
        >
          <Header
            sidebarMinimize={this.sidebarMinimize}
            miniActive={miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/*
            On the /maps/full-screen-maps route we want the map to be on full
            screen - this is not possible if the content and conatiner classes are
            present because they have some paddings which would make the map smaller
          */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{this.renderSwitch()}</div>
            </div>
          ) : (
            <div className={classes.map}>{this.renderSwitch()}</div>
          )}
          {this.renderTerminateModal()}
          {this.getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    );
  }
}

const DashboardClass = withFirestore(Dashboard);

const mapStateToProps = state => ({
  ui_view_permissions: state.user.ui_view_permissions,
  user: state.user.user,
});

export default compose(
  connect(
    mapStateToProps,
    { closeModal: closeDocumentControllerModal },
  ),
  withStyles(appStyle),
)(DashboardClass);
