import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirestore } from 'react-firestore';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import {
  Dialog, Slide, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  closeDocumentControllerModal,
  closeSecondaryDocumentControllerModal,
} from '../../actions';

export const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

const renderSuccess = (name, action) => {
  toast.success(`${name} ${action} successfully!`);
};

const styles = () => ({
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const GenericModal = ({
  id,
  children,
  secondaryChildren,
  closeModal,
  closeSecondaryModal,
  open,
  openSecondary,
  classes,
  hasCloseButton,
}) => {
  const childrenContent = id === 'secondary' ? secondaryChildren : children;
  const closeModalFunc = id === 'secondary' ? closeSecondaryModal : closeModal;
  const openModal = id === 'secondary' ? openSecondary : open;

  if (!childrenContent) {
    return <span />;
  }

  const closeButton = hasCloseButton ? (
    <IconButton onClick={closeModalFunc} className={classes.closeButton}>
      <CloseIcon />
    </IconButton>
  ) : null;

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={openModal}
      onClose={closeModalFunc}
      TransitionComponent={Transition}
    >
      {closeButton}
      {cloneElement(
        childrenContent, {
          ...childrenContent.props,
          handleClose: closeModalFunc,
          renderSuccess,
        },
      )}
    </Dialog>
  );
};

GenericModal.defaultProps = {
  id: '',
  hasCloseButton: false,
};

GenericModal.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  secondaryChildren: PropTypes.node.isRequired,
  closeSecondaryModal: PropTypes.func.isRequired,
  openSecondary: PropTypes.bool.isRequired,
  hasCloseButton: PropTypes.bool,
};

const GenericModalClass = withFirestore(GenericModal);

const mapStateToProps = state => ({
  open: state.modal.open,
  openSecondary: state.modal.openSecondary,
  children: state.modal.children,
  secondaryChildren: state.modal.secondaryChildren,
});
export default compose(
  connect(
    mapStateToProps,
    {
      closeModal: closeDocumentControllerModal,
      closeSecondaryModal: closeSecondaryDocumentControllerModal,
    },
  ),
  withStyles(styles),
)(GenericModalClass);
