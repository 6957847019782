import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Routes from './containers/routes';
import DocumentControllerModal from './components/document_controller_modal';
import ErrorBoundary from './ErrorBoundary';

console.log('%(VERSION)s');

const App = () => (
  <div id="outer-container">
    <ErrorBoundary>
      <Routes />
    </ErrorBoundary>
    <DocumentControllerModal id="primary" />
    <DocumentControllerModal id="secondary" hasCloseButton />
    <ToastContainer autoClose={5000} />
  </div>
);

export default App;
