import React from 'react';
import PropTypes from 'prop-types';
import PasswordSetup from './password_setup';
import UserDetails from './user_details';

const UserSettingsModalContainer = ({
  data, handleClose, renderSuccess, tab,
}) => (
  <div>
    {tab === 'details' && (
      <UserDetails data={data} renderSuccess={renderSuccess} handleClose={handleClose} />
    )}
    {tab === 'password' && (
      <PasswordSetup renderSuccess={renderSuccess} handleClose={handleClose} />
    )}
  </div>
);

UserSettingsModalContainer.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  renderSuccess: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
};

UserSettingsModalContainer.defaultProps = {
  data: {},
};

export default UserSettingsModalContainer;
