import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';

const SuggestionComponent = ({ parts, isHighlighted }) => (
  <MenuItem selected={isHighlighted} component="div">
    <div style={{ fontSize: 12 }}>
      {parts.map((part, index) => (part.highlight ? (
        <span key={String(index)} style={{ fontWeight: 500 }}>
          {part.text}
        </span>
      ) : (
        <strong key={String(index)} style={{ fontWeight: 300 }}>
          {part.text}
        </strong>
      )))}
    </div>
  </MenuItem>
);

SuggestionComponent.propTypes = {
  parts: PropTypes.array.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
};

export default withStyles({})(SuggestionComponent);
