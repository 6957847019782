import firebase from 'firebase/app';
import 'firebase/storage';
import { toast } from 'react-toastify';

export const onDelete = (name, doc_type, collection, firestore, id) => {
  const ref = firebase
    .storage()
    .ref()
    .child(`${collection}/${id}/${doc_type}/${name}`);
  const docRef = ref.toString();
  const result = new Promise((resolve) => {
    ref.delete().then(() => {
      const collectionRef = firestore.collection(`${collection}`).doc(`${id}`);

      const collectionTransaction = firestore
        .runTransaction(transaction => transaction.get(collectionRef).then((doc) => {
          if (!doc.exists) {
            return Promise.resolve({ code: 404, message: 'Document not found.' });
          }

          let docType = doc.data()[doc_type] || [];

          docType = docType.filter(
            obj => `${obj.name}` !== `${name}` && `${obj.ref}` !== `${docRef}`,
          );

          transaction.update(collectionRef, { [doc_type]: docType });
          return Promise.resolve({ code: 200, message: 'OK', data: docType });
        }))
        .then(res => res.data);

      return Promise.all([collectionTransaction]).then(res => resolve(res[0]));
    });
  });
  return Promise
    .all([result])
    .then((res) => {
      toast.success('file deleted successfully!');
      return [res];
    });
};
// this function converts base64 strings to blobs
const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const uploadFile = (
  file,
  collection,
  id,
  doc_type,
  firestore,
  ServerTime,
  should_overwrite = false,
  base64 = false,
  isPublic = false,
) => {
  const mimeType = {
    contentType: file.type,
  };
  let path = `${collection}/${id}/${doc_type}/${file.name}`;
  if (isPublic) {
    path = `public/${path}`;
  }
  let ref = firebase
    .storage()
    .ref()
    .child(path);
  const docRef = ref.toString();
  // create a copy reference to the parameter to avoid linter error
  const fileCopy = file;
  // assign reference to the uploaded doc. This prevents onDrop from
  // erroring out when clicking on recently-uploaded documents
  fileCopy.ref = docRef;
  if (base64) {
    // converts from base64 string to blob
    const blob = b64toBlob(file.FileData, file.type);
    ref = ref.put(blob, mimeType);
  } else {
    ref = ref.put(file, mimeType);
  }
  return ref
    .then(res => console.log('file uploaded successfully!: ', res))
    .then(() => toast.success('File uploaded successfully!'))
    .then(() => ServerTime.serverTime())
    .then((serverTime) => {
      const collectionRef = firestore.collection(`${collection}`).doc(`${id}`);
      const doc_to_save = {
        name: `${file.name}`,
        ref: docRef,
        upload_date: serverTime,
      };

      if (should_overwrite) {
        const doc_to_overwrite = [doc_to_save];
        const setPromise = collectionRef
          .update({
            [doc_type]: doc_to_overwrite,
          });
        return Promise.all([setPromise]);
      }
      const collectionTransaction = firestore
        .runTransaction(transaction => transaction
          .get(collectionRef).then((doc) => {
            if (!doc.exists) {
              return Promise.resolve({ code: 404, message: 'Document not found.' });
            }

            const docType = doc.data()[doc_type] || [];
            const index = docType.findIndex(
              obj => `${obj.name}` === `${file.name}` && `${obj.ref}` === `${docRef}`,
            );
            if (index === -1) {
              docType.push(doc_to_save);
            }

            transaction.update(collectionRef, { [doc_type]: docType });
            return Promise.resolve({ code: 200, message: 'OK', data: docType });
          }));
      return Promise.all([collectionTransaction]);
    })
    .then(() => file)
    .catch((err) => {
      console.error('unsuccessfully uploaded file: ', err);
      throw Error(err);
    });
};

export const onDrop = (
  acceptedFiles,
  doc_type,
  collection,
  firestore,
  id,
  ServerTime,
  isCarrierPortal = false,
  should_overwrite = false,
) => {
  const files = acceptedFiles.map(async (file) => {
    if (typeof file.type === 'string' && `${file.type}`.toLowerCase().includes('pdf') && isCarrierPortal) {
      const formData = new FormData();
      formData.append('File', file, file.name);
      const token = await firebase.functions().httpsCallable('obtainToken/')({});
      const {
        data: { Id },
      } = token;
      const response = await fetch(`https://v2.convertapi.com/convert/pdf/to/split?Token=${Id}`, {
        body: formData,
        method: 'POST',
        credentials: 'same-origin',
      });
      const data = await response.json();
      const { Files } = data;
      const res = [];
      for (let i = 0; i < Files.length; i++) {
        const f = Files[i];
        const { FileName } = f;
        const fileCopy = f;
        fileCopy.type = 'application/pdf';
        fileCopy.name = FileName;
        const isBase64 = true;
        const isPublic = true;
        res.push(uploadFile(
          fileCopy, collection, id, doc_type, firestore,
          ServerTime, should_overwrite, isBase64, isPublic,
        ));
      }
      return Promise.all(res);
    }
    const result = await uploadFile(
      file, collection, id, doc_type, firestore, ServerTime, should_overwrite,
    );
    return result;
  });
  return Promise.all(files);
};
