import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore } from 'react-firestore';
import { toast } from 'react-toastify';
import firebase from 'firebase/app';
import 'firebase/auth';


// import { Manager, Target, Popper } from "react-popper";
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ClickAwayListener,
  Grow,
  Hidden,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';

// @material-ui/icons
import {
  Notifications,
  Person,
  Message,
} from '@material-ui/icons';

// core components
import Button from '../CustomButtons/Button';

import headerLinksStyle from '../../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle';

import { openDocumentControllerModal } from '../../../actions';
import UserSettings from '../../document_controller_modal/user_settings_modal';
import DialogComponent from '../../dialog_component';
import PushNotificationBody from '../../push_notification';
import financial from '../../../lib/financial';

class HeaderLinks extends React.Component {
  state = {
    message: '',
    notification_modal: false,
    open: false,
    roles: [],
    user_id: '',
    carrier_requests: null,
    credit_requests: null,
    profileMenuOpen: false,
    profileAnchorEl: null,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    firestore: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    rtlActive: PropTypes.bool,
    ui_view_permissions: PropTypes.array,
    user: PropTypes.object,
  };

  static defaultProps = {
    rtlActive: false,
    ui_view_permissions: [],
    user: {},
  };

  componentDidMount() {
    this.checkPermissions();
  }

  handleChange = (name, event) => {
    this.setState({ [name]: event.target.value });
  };

  handleClick = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSettingsClick = () => {
    const {
      openModal,
      user: {
        uid,
      },
    } = this.props;
    openModal(<UserSettings id={uid} tab="details" />);
  }

  handleSendMessage = async () => {
    const {
      message,
      roles,
      user_id,
    } = this.state;
    const { firestore } = this.props;
    if (user_id) {
      firestore
        .collection('user_profiles')
        .doc(`${user_id}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return toast.warn("This isn't the id you're looking for");
          }
          const { fcmDesktopToken, username } = doc.data();
          if (!fcmDesktopToken) {
            return toast.warn("The user you're trying to message isn't registered to accept notifications at this time");
          }
          // Add token to payload
          const payload = {
            ...message,
            token: fcmDesktopToken,
          };

          return firebase
            .messaging()
            .send(payload)
            .catch(err => console.log(
              `Error sending message to user ${username}: `,
              err,
            ));
        })
        .catch(err => toast.warn('There was an error messaging the user: ', err));
    }
    if (roles.length > 0) {
      // cycle through all the users with that role
      roles.forEach((role) => {
        firestore
          .collection('user_profiles')
          .where('fcmDesktopToken', '>=', '')
          .where('roles', 'array-contains', role)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              return toast.warn(`there are no users with the role ${role} that are authorized to receive notifications`);
            }
            // cycle through the results
            querySnapshot.forEach((doc) => {
              const { fcmDesktopToken, username } = doc.data();
              // for users with an fcmtoken, send a message
              if (fcmDesktopToken) {
                // Add token to payload
                const payload = {
                  ...message,
                  token: fcmDesktopToken,
                };

                firebase
                  .messaging()
                  .send(payload)
                  .catch(err => console.log(
                    `Error sending message to user ${username}: `,
                    err,
                  ));
              }
            });
            return Promise.resolve();
          })
          .catch(err => console.error('error in my implementation: ', err));
      });
    }
    this.setState({ notification_modal: false });
  }

  handleCloseMessageModal = () => {
    this.setState({ notification_modal: false });
  }

  handleOpenMessageModal = () => {
    this.setState({ notification_modal: true });
  }

  checkPermissions = () => {
    const { ui_view_permissions } = this.props;
    const hasCarrierRequestPermission = ui_view_permissions
      && ui_view_permissions.find(ui_view => (
        ui_view.slug === 'carrier_request_notifications'
      ));
    const hasCreditRequestPermission = ui_view_permissions
      && ui_view_permissions.find(ui_view => (
        ui_view.slug === 'credit_request_notifications'
      ));

    if (hasCarrierRequestPermission) {
      this.checkPendingCarrierRequests();
    }

    if (hasCreditRequestPermission) {
      this.checkPendingCreditRequests();
    }
  }

  renderNotificationCount = () => {
    const { carrier_requests, credit_requests } = this.state;
    const { classes } = this.props;
    let total = 0;

    if (carrier_requests) {
      total += carrier_requests.length;
    }

    if (credit_requests) {
      total += credit_requests.length;
    }

    if (!total) {
      return '';
    }

    return <span className={classes.notifications}>{total}</span>;
  }

  checkPendingCarrierRequests = () => {
    const { firestore } = this.props;

    return firestore
      .collection('truckboard_team_requests')
      .where('approved', '==', false)
      .where('terminated', '==', false)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          this.setState({
            carrier_requests: null,
          });
        } else {
          const carrier_requests = [];
          querySnapshot.forEach((doc) => {
            let message = '';
            const { carrier_name } = doc.data();

            message = `Pending Carrier Update: Pending insurance update for ${carrier_name}`;
            carrier_requests.unshift(message);
          });
          this.setState({
            carrier_requests,
          });
        }
      });
  }

  checkPendingCreditRequests = () => {
    const { firestore } = this.props;

    return firestore
      .collection('creditrequests')
      .where('load_ready', '==', false)
      .where('terminated', '==', false)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          this.setState({
            credit_requests: null,
          });
        } else {
          const credit_requests = [];
          querySnapshot.forEach((doc) => {
            let message = '';
            const {
              agent,
              customer_name,
              request_amount,
            } = doc.data();

            message = `Pending Credit Request: ${agent} requests $${financial.toDollar(request_amount)} for ${customer_name}`;
            credit_requests.unshift(message);
          });
          this.setState({
            credit_requests,
          });
        }
      });
  }

  renderNotifications = (type, request, index) => {
    const { classes } = this.props;
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);

    if (type === 'carrier') {
      return (
        <Link key={type + index} to="/new_load">
          <MenuItem onClick={this.handleClose} className={dropdownItem}>
            {request}
          </MenuItem>
        </Link>
      );
    }

    return (
      <Link key={type + index} to="/credit_list">
        <MenuItem onClick={this.handleClose} className={dropdownItem}>
          {request}
        </MenuItem>
      </Link>
    );
  }

  renderName = () => {
    const { user } = this.props;
    if (!this.props || !user) {
      return '';
    }
    const {
      user: {
        firstname,
        lastname,
      },
    } = this.props;
    const firstnameExists = typeof firstname !== 'undefined' && firstname !== '';
    const lastnameExists = typeof lastname !== 'undefined' && lastname !== '';
    if (!firstnameExists && !lastnameExists) {
      return '';
    }
    if (!firstnameExists && lastnameExists) {
      return lastname;
    }
    if (firstnameExists && !lastnameExists) {
      return firstname;
    }
    return `${lastname}, ${firstname}`;
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ profileMenuOpen: true, profileAnchorEl: event.currentTarget });
  }

  handleProfileMenuClose = () => {
    this.setState({ profileMenuOpen: false });
  }

  signout = () => {
    firebase.auth().signOut();
  }

  render() {
    const {
      classes,
      rtlActive,
      user,
    } = this.props;
    const {
      message,
      notification_modal,
      open,
      roles,
      user_id,
      carrier_requests,
      credit_requests,
      profileMenuOpen,
      profileAnchorEl,
    } = this.state;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });

    return (
      <div className={wrapper}>
        {user
          && user.role_admin
          && (
            <Button
              color="transparent"
              aria-label="message"
              justIcon
              onClick={this.handleOpenMessageModal}
            >
              <Message className={`${classes.headerLinksSvg} ${classes.searchIcon}`} />
            </Button>
          )}
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={open ? 'menu-list' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : '',
            }}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
          >
            <Notifications
              className={
                `${classes.headerLinksSvg
                } ${
                rtlActive ? `${classes.links} ${classes.linksRTL}` : classes.links}`
              }
            />
            {this.renderNotificationCount()}
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClick}
                onKeyPress={this.handleClick}
                className={classes.linkText}
                role="button"
                tabIndex={-1}
              >
                {rtlActive ? 'إعلام' : 'Notification'}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !open,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      {carrier_requests
                        ? carrier_requests.map((request, index) => (
                          this.renderNotifications('carrier', request, index)
                        ))
                        : <span />
                      }
                      {credit_requests
                        ? credit_requests.map((request, index) => (
                          this.renderNotifications('credit', request, index)
                        ))
                        : <span />
                      }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <Button
          color="transparent"
          aria-label="Person"
          className={classes.button}
          muiClasses={{
            label: classes.labelRTL,
          }}
          onClick={this.handleProfileMenuOpen}
          onMouseOver={this.handleProfileMenuOpen}
          onFocus={this.handleProfileMenuOpen}
        >
          {this.renderName()}
          {
            (user && user.photoURL)
            && (
              <div className={classes.avatarImgContainer}>
                <img src={user.photoURL} className={classes.avatarImg} alt="..." />
              </div>
            )
          }
          {
            (!user || !user.photoURL)
            && (
              <Person className={classes.rightIcon} />
            )
          }
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={profileAnchorEl}
          keepMounted
          open={profileMenuOpen}
          onClose={this.handleProfileMenuClose}
          MenuListProps={{ onMouseLeave: this.handleProfileMenuClose }}
        >
          <MenuItem onClick={this.handleSettingsClick}>My account</MenuItem>
          <MenuItem onClick={this.signout}>Logout</MenuItem>
        </Menu>
        <DialogComponent
          body={(
            <PushNotificationBody
              handleChange={this.handleChange}
              message={message}
              roles={roles}
              user_id={user_id}
            />
          )}
          closeLabel="Close"
          confirmLabel="Send"
          handleConfirm={this.handleSendMessage}
          open={notification_modal}
          disableConfirm={(roles.length === 0 && user_id.length === 0) || message.length === 0}
          onClose={this.handleCloseMessageModal}
        />
      </div>
    );
  }
}

const HeaderLinksClass = withFirestore(HeaderLinks);

const mapStateToProps = state => ({
  user: state.user.user,
  ui_view_permissions: state.user.ui_view_permissions,
});

export default compose(
  withStyles(headerLinksStyle),
  connect(
    mapStateToProps,
    {
      openModal: openDocumentControllerModal,
    },
  ),
)(HeaderLinksClass);
