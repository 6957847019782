const fontDetails = {
  fontWeight: 700,
  color: '#4A4A4A',
  textTransform: 'none',
};

const typography = {
  fontFamily: [
    '"Proxima Nova"',
    '"Roboto"',
    '"Helvetica"',
    '"Arial"',
    'sans-serif',
  ].join(','),
  fontSize: 13,
  ...fontDetails,
  h1: {
    fontSize: 26,
    ...fontDetails,
  },
  h2: {
    fontSize: 18,
    ...fontDetails,
  },
  h3: {
    fontSize: 16,
    ...fontDetails,
  },
  h4: {
    fontSize: 14,
    ...fontDetails,
  },
  h5: {
    fontSize: 14,
    ...fontDetails,
  },
  h6: {
    fontSize: 18,
    ...fontDetails,
  },
  body1: {
    ...fontDetails,
  },
  body2: {
    fontWeight: 400,
    color: '#4A4A4A',
  },
  subtitle1: {
    ...fontDetails,
  },
  subtitle2: {
    fontWeight: 400,
    color: '#4A4A4A',
  },
};

export default typography;
