import { SET_USER, SET_AUTH_USER } from '../constants';

const appReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user ? Object.assign({}, action.user) : null, // needs to be immutable
        ui_view_permissions: action.ui_view_permissions.slice() || [], // needs to be immutable
        customer_id: action.customer_id || null, // needs to be immutable
      };
    case SET_AUTH_USER:
      return {
        ...state,
        authuser: action.authuser,
      };
    default:
      return state;
  }
};

export default appReducer;
