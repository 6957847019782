/* eslint-disable */
import React from 'react';
import { toast } from 'react-toastify';

import {
  DirectionsBus,
} from '@material-ui/icons';

import LoadsPastDue from '../components/loads/index';

export const renderSuccess = (name, action) => {
  toast.success(`${name} ${action} successfully!`);
};

const filteredSlugs = (perm, req) => perm.filter(p => p.slug.includes(req));

const dashRoutes = [
  {
    path: '/loadspastdue',
    name: 'Loads Past Due',
    mini: 'L',
    icon: DirectionsBus,
    component: props => <LoadsPastDue {...props} renderSuccess={renderSuccess} />,
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/loadspastdue',
    name: 'Dashboard',
  },
];
export default dashRoutes;
