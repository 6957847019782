import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withFirestore } from 'react-firestore';
import firebase from 'firebase';
import 'firebase/functions';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Edit,
} from '@material-ui/icons';
import { toast } from 'react-toastify';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  autosuggestStyle,
  autosuggestTheme,
  InputComponent,
  OptionsContainer,
  SuggestionComponent,
} from '../autosuggest/index';
import { containerSpacing } from '../../common';
import { Countries } from '../../dropdown_options/index';
import RenderStatesComponent from '../render_states_component/index';
import {
  handleCollectionSearch,
  reduceObjectKeys,
} from '../../utils';
import PhoneComponent, { removeAddons } from '../phone_mask';
import { openSecondaryDocumentControllerModal } from '../../actions';
import ValidateUserModal from './validate_user_modal';
import Dropzone from '../dropzone';
import { onDelete, onDrop } from '../dropzone/utils';

const styles = theme => ({
  container: {
    overflowY: 'inherit',
  },
  formControl: {
    marginTop: 16,
  },
  ...autosuggestStyle(theme),
});

class UserDetails extends Component {
  state = {
    ...this.initialState,
    disabled: true,
    email: '',
    profile_photo: [],
    saving: false,
    suggestions: [],
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object,
    firestore: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    openSecondaryModal: PropTypes.func.isRequired,
    renderSuccess: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {},
  };

  componentDidMount() {
    const {
      data: { id },
      data,
    } = this.props;

    // will only set the default state on an edit
    if (id) {
      this.setState({
        ...data,
      });
    }
  }

  get initialState() {
    return {
      address: '',
      branchname: '',
      cellno: '',
      city: '',
      country: '',
      ext: '',
      firstname: '',
      lastname: '',
      middlename: '',
      state: '',
      username: '',
      zipcode: '',
    };
  }

  handleChange = (name, event) => {
    if (event && event.target && event.target.value) {
      this.setState({ [name]: event.target.value });
    } else {
      this.setState({ [name]: '' });
    }
  };

  handleAutoSuggestChange = name => (event, { newValue }) => {
    if (typeof newValue === 'object') {
      this.setState({
        [name]: newValue.name,
      });
    } else {
      this.setState({
        [name]: newValue,
      });
    }
  };

  handleDelete = async (name, doc_type) => {
    const { firestore, data: { id } } = this.props;
    this.toggleEdit();
    await onDelete(name, doc_type, 'user_profiles', firestore, id);
    this.setState({
      [doc_type]: [],
    },
      () => this.toggleEdit());
  };

  handleDrop = async (acceptedFiles, doc_type, ServerTime) => {
    const { firestore, data: { id } } = this.props;
    this.toggleEdit();
    const result = await onDrop(acceptedFiles, doc_type, 'user_profiles', firestore, id, ServerTime, false, true);
    this.setState({
      [doc_type]: [...result],
    },
      () => this.toggleEdit());
  };

  handleSearch = async ({ value }) => {
    const { firestore } = this.props;
    const searchParams = ['name_search', 'id_search'];
    const results = await handleCollectionSearch(value, firestore, 'branches', searchParams);
    this.setState({ suggestions: results });
  };

  handleValidatePassword = (email, password) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => this.handleSave())
      .catch((err) => {
        console.error('error validating password in user details: ', err);
        return toast.warn('Looks like the password you submitted was invalid, please try again');
      });
  }

  handleOpenValidateModal = () => {
    const { openSecondaryModal } = this.props;
    openSecondaryModal(
      <ValidateUserModal
        handleValidatePassword={this.handleValidatePassword}
      />,
    );
  }

  onClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = suggestion => suggestion;

  toggleEdit = () => {
    this.setState(prevState => ({ disabled: !prevState.disabled }));
  }

  displayWarning = prop => toast.warn(`Uh oh! Looks like you're trying to update your ${prop} with one that already exists`)

  updateCarriersAuthNumber = async (old_phone, new_phone) => {
    const { firestore } = this.props;
    const oldPhone = old_phone.slice(-10); // removes any +countrycode
    const newPhone = new_phone.slice(-10); // removes any +countrycode
    const carriersToUpdate = await firestore
      .collection('carriers')
      .where('app_auth_phone_no', 'array-contains', `${oldPhone}`)
      .where('terminated', '==', false)
      .get()
      .then((querySnapshot) => {
        const result = [];
        if (querySnapshot.empty) {
          return result;
        }
        querySnapshot.forEach((doc) => {
          result.push(doc.id);
        });
        return result;
      })
      .catch(err => console.error(
        'There has been an error access the carrier by the carrier phone number: ',
        err,
      ));

    carriersToUpdate.forEach((carrier_id) => {
      const carrierRef = firestore
        .collection('carriers')
        .doc(`${carrier_id}`);

      firestore
        .runTransaction(transaction => transaction
          .get(carrierRef)
          .then((doc) => {
            if (!doc.exists) {
              return Promise.resolve({ code: 404, message: 'Carrier not found.' });
            }

            let new_auth_numbers = doc.data().app_auth_phone_no || [];
            // remove carrier from array if it exists
            new_auth_numbers = new_auth_numbers.map((number) => {
              if (number === oldPhone) {
                return newPhone;
              }
              return number;
            });

            return transaction.update(carrierRef, { app_auth_phone_no: new_auth_numbers });
          }));
    });
  }

  handleSave = async () => {
    this.setState({ saving: true });
    const {
      data: {
        uid,
        role_driver,
      },
      firestore,
      handleClose,
      renderSuccess,
    } = this.props;
    const { profile_photo } = this.state;
    const update = reduceObjectKeys(
      Object.keys(this.initialState), this.state,
    );

    const user = firebase.auth().currentUser;
    const {
      phoneNumber,
      displayName,
    } = user;

    update.cellno = removeAddons(update.cellno);

    const phoneChanged = ((update.cellno !== '')
      && (
        (!phoneNumber
          && typeof update.cellno !== 'undefined'
        )
        || (phoneNumber
          && update.cellno
          && `+1${update.cellno}` !== phoneNumber
        )
      ));

    const nameChanged = (update.username !== ''
      && (
        (!displayName
          && typeof update.username !== 'undefined'
        )
        || (displayName
          && update.username
          && update.username !== displayName
        )
      ));

    let numberIsNew = true;
    let nameIsNew = true;
    const update_profile = {};
    const update_number = {};

    if (phoneChanged) {
      // we know the phonenumber has been updated and
      // need to check to see if we can update the number given
      const filter = ['cellno', '==', update.cellno];
      numberIsNew = await firebase
        .functions()
        .httpsCallable('isUserPropInUse/')({
          uid,
          filter,
        }).then(res => res.data);
      if (numberIsNew) {
        update_number.phoneNumber = `+1${update.cellno}`;
      }
    }
    if (nameChanged) {
      // we know the username has been updated and
      // need to check to see if we can update the username
      const filter = ['username', '==', update.username];
      nameIsNew = await firebase
        .functions()
        .httpsCallable('isUserPropInUse/')({
          uid,
          filter,
        }).then(res => res.data);
      if (nameIsNew) {
        update_profile.displayName = update.username;
      }
    }

    if (profile_photo.length > 0) {
      // get the url for the photo and save it to the user
      const [file] = profile_photo;
      const ref = file.ref ? file.ref : file;
      const storage = firebase.storage();
      const gsReference = storage.refFromURL(ref);
      update_profile.photoURL = await gsReference
        .getDownloadURL()
        .then(url => url);
      update.photoURL = update_profile.photoURL;
    } else {
      update_profile.photoURL = '';
      update.photoURL = '';
    }

    if (numberIsNew === false) {
      return this.displayWarning('phone number');
    }
    if (nameIsNew === false) {
      return this.displayWarning('username');
    }

    if (Object.keys(update_profile).length > 0) {
      user
        .updateProfile(update_profile)
        .catch(() => {
          toast.warn('looks like there was an error updating your username');
          return Promise.reject(Error('There was an error updating your username'));
        });
    }

    if (typeof update_number.phoneNumber !== 'undefined') {
      // in order to update the phone number, we first need to verify that the number exists
      const applicationVerifier = new firebase
        .auth
        .RecaptchaVerifier('recaptcha-container');

      const provider = new firebase.auth.PhoneAuthProvider();
      await provider
        .verifyPhoneNumber(`${update_number.phoneNumber}`, applicationVerifier)
        .then((verificationId) => {
          const verificationCode = window.prompt('Please enter the verification code that was sent to your mobile device.'); // eslint-disable-line
          return firebase
            .auth
            .PhoneAuthProvider
            .credential(verificationId, verificationCode);
        })
        .then(phoneCredential => user.updatePhoneNumber(phoneCredential))
        .then(() => {
          // if the role is driver, we need to update the auth in the carriers
          if (role_driver) {
            this.updateCarriersAuthNumber(phoneNumber, update_number.phoneNumber);
          }
        })
        .catch(() => {
          this.displayWarning('phone number');
          return Promise.reject(Error('There was an error saving the phone number'));
        });
    }

    return firestore
      .collection('user_profiles')
      .doc(`${uid}`)
      .update(update)
      .then(() => renderSuccess('User information', 'updated'))
      .then(() => handleClose());
  }

  renderTextField = (label, val, change, md, disabled) => (
    <Grid item xs={12} md={md}>
      <TextField
        label={label}
        fullWidth
        value={val || ''}
        onChange={e => this.handleChange(change, e)}
        margin="normal"
        disabled={disabled}
      />
    </Grid>
  );

  renderAgentInformation = () => {
    const {
      cellno,
      disabled,
      email,
      ext,
      username,
    } = this.state;
    return (
      <Grid container spacing={containerSpacing}>
        {this.renderTextField('User Name', username, 'username', 4, disabled)}
        {this.renderTextField('Email', email, 'email', 4, true)}
        <Grid item xs={12} md={4}>
          <PhoneComponent
            changeValue="cellno"
            disabled={disabled}
            handleChange={this.handleChange}
            label="Mobile Phone"
            value={cellno}
          />
        </Grid>
        {this.renderTextField('Office Ext', ext, 'ext', 4, disabled)}
        <Grid item xs={4}>
          {this.renderOfficeInfo()}
        </Grid>
        <Grid item xs={4}>
          <div id="recaptcha-container" />
        </Grid>
      </Grid>
    );
  }

  renderOfficeInfo = () => {
    const {
      branchname,
      disabled,
      suggestions,
    } = this.state;
    const {
      classes,
    } = this.props;

    const inputProps = {
      placeholder: 'Type the name of a office',
      value: branchname,
      onChange: this.handleAutoSuggestChange('branchname'),
      classes,
    };

    return (
      <Grid container spacing={containerSpacing}>
        <Grid
          item
          xs={12}
          classes={{
            root: classes.formControl,
          }}
        >
          <div className={classes.root}>
            <InputLabel shrink htmlFor="claims-agent-label-placeholder">
              Office
            </InputLabel>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.handleSearch}
              onSuggestionsClearRequested={this.onClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={(suggestion, { query, isHighlighted }) => (
                <SuggestionComponent
                  parts={parse(suggestion.name, match(suggestion.name, query))}
                  isHighlighted={isHighlighted}
                />
              )}
              renderInputComponent={input => (
                <InputComponent
                  disabled={disabled}
                  inputProps={input}
                />
              )}
              inputProps={inputProps}
              theme={autosuggestTheme(classes)}
              renderSuggestionsContainer={options => <OptionsContainer options={options} />}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  renderPersonalInformation = () => {
    const { classes } = this.props;
    const {
      address,
      city,
      country,
      disabled,
      firstname,
      lastname,
      middlename,
      state,
      zipcode,
    } = this.state;
    return (
      <Grid container spacing={containerSpacing}>
        {this.renderTextField('First Name', firstname, 'firstname', 4, disabled)}
        {this.renderTextField('Last Name', lastname, 'lastname', 4, disabled)}
        {this.renderTextField('Middle Name', middlename, 'middlename', 4, disabled)}
        {this.renderTextField('Address', address, 'address', 12, disabled)}
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.formControl}
            disabled={disabled}
            fullWidth
          >
            <InputLabel htmlFor="country-simple">Country</InputLabel>
            <Countries
              change_country="country"
              handleChange={this.handleChange}
              country={country}
            />
          </FormControl>
        </Grid>
        {this.renderTextField('City', city, 'city', 3, disabled)}
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.formControl}
            disabled={disabled}
            fullWidth
          >
            <InputLabel htmlFor="state-simple">State</InputLabel>
            <RenderStatesComponent
              state={state}
              country={country}
              change_state="state"
              handleChange={this.handleChange}
            />
          </FormControl>
        </Grid>
        {this.renderTextField('Zip Code', zipcode, 'zipcode', 3, disabled)}
      </Grid>
    );
  }

  render() {
    const {
      classes,
      handleClose,
    } = this.props;

    const {
      disabled,
      profile_photo,
      saving,
    } = this.state;

    return (
      <div>
        <DialogContent classes={{ root: classes.container }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={11}>
                  <Typography gutterBottom variant="h2">
                    Personal Information
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Button size="small" className={classes.margin} disabled={!disabled} onClick={this.toggleEdit}>
                    <Edit />
                  </Button>
                </Grid>
              </Grid>
              <Divider />
              {this.renderPersonalInformation()}
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h2">
                Agent Information
              </Typography>
              <Divider />
              {this.renderAgentInformation()}
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <Typography gutterBottom variant="h2">
                Upload Photo
              </Typography>
              <Dropzone
                disabled={disabled}
                onDrop={this.handleDrop}
                onDelete={this.handleDelete}
                doc_type="profile_photo"
                docs={profile_photo}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {!saving
          && (
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSave}
                disabled={disabled}
              >
                Save
              </Button>
            </DialogActions>
          )}
        {saving
          && (
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                disabled
              >
                Saving...
                <CircularProgress size={20} />
              </Button>
            </DialogActions>
          )}
      </div>
    );
  }
}

const UserDetailsClass = withFirestore(UserDetails);

export default compose(
  withStyles(styles),
  connect(
    null,
    {
      openSecondaryModal: openSecondaryDocumentControllerModal,
    },
  ),
)(UserDetailsClass);
