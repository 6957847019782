import React from 'react';
import {
  CircularProgress,
  Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  loader: {
    minHeight: 300,
  },
});

const Loading = ({ classes }) => (
  <Paper elevation={2} className={classes.loader}>
    {/* The circular progress indicator doesn't always appear where the
    table will go, we need a better indicator */}
    <div>Loading...</div>
    <CircularProgress className={classes.progress} size={100} />
  </Paper>
);

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
