import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withFirestore } from 'react-firestore';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { containerSpacing } from '../../common';
import { reduceObjectKeys } from '../../utils';
import SubmitButton from '../submit_button';
import RadioButtons from '../radio_buttons/index';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    marginTop: 16,
  },
  overflow: {
    overflowY: 'inherit',
  },
  radio: {
    display: 'block',
  },
});

class LoadFormModal extends Component {
  state = {
    payment_status: '',
    amount: 0,
    type: '',
    comments: '',
    date: null,
    checknum: '',
    saving: false,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    firestore: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.object,
    load_id: PropTypes.string.isRequired,
    current_user: PropTypes.object.isRequired,
    current_customer_id: PropTypes.string.isRequired,
  };

  static defaultProps = {
    data: {},
  };

  componentDidMount() {
    const {
      data: {
        payment_status,
        amount,
        checknum,
        comments,
        date,
      },
    } = this.props;

    // will only set the default state on an edit
    this.setState({
      payment_status: payment_status || '',
      amount: amount || '',
      checknum: checknum || '',
      comments: comments || '',
      date: date ? date.toDate() : null,
    });
  }

  handleChange = (name, event) => {
    this.setState({ [name]: event.target.value });
  };

  handleDateChange = formName => (date) => {
    this.setState({ [formName]: date.toDate() });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ saving: true });
    const {
      firestore,
      handleClose,
      load_id,
      current_user,
      current_customer_id,
    } = this.props;
    const data = reduceObjectKeys(
      [
        'payment_status',
        'amount',
        'checknum',
        'comments',
        'date',
      ], this.state,
    );

    switch (data.payment_status) {
      case ('issue'):
        if (!data.comments) {
          toast.error('Please fill in the comment box to clarify your issue');
          this.setState({ saving: false });
          return;
        }
        break;
      case ('going_to_pay'):
        if (!data.date || !data.amount || !data.checknum) {
          toast.error('Please be sure to fill out all the fields.');
          this.setState({ saving: false });
          return;
        }
        break;
      case ('paid'):
        // no error checking
        break;
      default:
        toast.error('Please select a payment status.');
        this.setState({ saving: false });
        return;
    }

    const factRef = firestore
      .collection('views')
      .doc('customer')
      .collection('public')
      .doc(`${current_customer_id}`)
      .collection('loads_overdue')
      .doc(`${load_id}`)
      .collection('facts_customer');
    factRef.add({
      customer_feedback: data,
      meta_change: {
        user_name: current_user.username,
        user_uid: current_user.uid,
        action: 'Customer Feedback',
        origin: 'user',
      },
    })
    .then(() => {
      toast.success('Load information successfully updated! Thank you.');
      handleClose();
    });
  };

  renderPermissionViewInfo = () => {
    const { classes } = this.props;
    const {
      payment_status,
      amount,
      checknum,
      comments,
      date,
    } = this.state;
    return (
      <Grid container spacing={containerSpacing}>
        <Grid item xs={4}>
          <Grid container spacing={containerSpacing}>
            <Grid item xs={12}>
              <RadioButtons
                label="Payment Status"
                radioButtons={[
                  {
                    name: 'paid',
                    value: 'paid',
                    label: 'Paid',
                  },
                  {
                    name: 'issue',
                    value: 'issue',
                    label: 'There is an issue',
                  },
                  {
                    name: 'going_to_pay',
                    value: 'going_to_pay',
                    label: 'Going to pay',
                  },
                ]}
                checked={payment_status}
                checkFunc={(e) => {
                  this.handleChange('payment_status', e);
                }}
                classes={{ root: classes.radio }}
              />
            </Grid>
            {
              payment_status === 'going_to_pay'
              && (
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    label="Date"
                    value={date}
                    format="MM/DD/YYYY"
                    onChange={this.handleDateChange('date')}
                    variant="inline"
                    disableToolbar
                  />
                </Grid>
              )
            }
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={containerSpacing}>
            {
              payment_status === 'going_to_pay'
              && (
                <Grid item xs={6}>
                  <TextField
                    id="filled-full-width"
                    label="Amount"
                    value={amount || ''}
                    onChange={e => this.handleChange('amount', e)}
                    required={payment_status === 'going_to_pay'}
                    margin="normal"
                    type="number"
                    fullWidth
                  />
                </Grid>
              )
            }
            {
              payment_status === 'going_to_pay'
              && (
                <Grid item xs={6}>
                  <TextField
                    id="filled-full-width"
                    label="Check/ACH (Check #)"
                    value={checknum || ''}
                    onChange={e => this.handleChange('checknum', e)}
                    required={payment_status === 'going_to_pay'}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              )
            }
            <Grid item xs={12} md={12}>
              <TextField
                label="Comments"
                fullWidth
                required={payment_status === 'issue'}
                value={comments || ''}
                onChange={e => this.handleChange('comments', e)}
                margin="normal"
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, handleClose } = this.props;
    const { id, saving } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <DialogTitle id="max-width-dialog-title">
          Load Payment Update
        </DialogTitle>
        <DialogContent classes={{ root: classes.overflow }}>
          <div className={classes.container}>
            {this.renderPermissionViewInfo()}
          </div>
        </DialogContent>
        <SubmitButton
          closeLabel="Cancel"
          handleClose={handleClose}
          confirmLabel={id ? 'Update' : 'Submit'}
          saving={saving}
        />
      </form>
    );
  }
}

const mapStateToProps = state => ({
  current_user: state.user.user,
  current_customer_id: state.user.customer_id,
});

const LoadFormModalClass = withFirestore(LoadFormModal);
export default compose(
  connect(
    mapStateToProps,
  ),
  withStyles(styles),
)(LoadFormModalClass);
