import { combineReducers } from 'redux';
import appReducer from './user';
import documentControllerModalReducer from './modal';
import createLoadReducer from './create_load';

export default combineReducers({
  user: appReducer,
  modal: documentControllerModalReducer,
  createLoad: createLoadReducer,
});
