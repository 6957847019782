import React from 'react';
import Login from '../containers/login';

const publicOnlyRoutes = [
  {
    path: '/login/:code',
    name: 'Login',
    component: props => (
      <Login {...props} />
    ),
  },
  { path: '/login', name: 'Login', component: Login },
];

export default publicOnlyRoutes;
