import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { containerSpacing } from '../../common';
import ValidateUserModal from './validate_user_modal';
import { openSecondaryDocumentControllerModal } from '../../actions';

const styles = ({
  container: {
    overflowY: 'inherit',
  },
});

class PasswordSetup extends Component {
  state = {
    confirm_password: '',
    new_password: '',
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    openSecondaryModal: PropTypes.func.isRequired,
    renderSuccess: PropTypes.func.isRequired,
  };

  handleChange = (name, event) => {
    if (event && event.target && event.target.value) {
      this.setState({ [name]: event.target.value });
    } else {
      this.setState({ [name]: '' });
    }
  };

  handleOpenValidateModal = () => {
    const { openSecondaryModal } = this.props;
    openSecondaryModal(
      <ValidateUserModal
        handleValidatePassword={this.handleValidatePassword}
      />,
    );
  }

  handleValidatePassword = (email, password) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => this.handleSave())
      .catch((err) => {
        console.error('error validating password in password setup: ', err);
        return toast.warn('Looks like the password you submitted was invalid, please try again');
      });
  }

  handleSave = () => {
    const user = firebase.auth().currentUser;
    const { handleClose, renderSuccess } = this.props;
    const { new_password } = this.state;
    user
      .updatePassword(new_password)
      .then(() => renderSuccess('User password', 'updated'))
      .then(() => handleClose())
      .catch(() => this.handleOpenValidateModal());
  }

  checkPwEquality = () => {
    const {
      new_password,
      confirm_password,
    } = this.state;
    if (new_password.length < 6 && confirm_password.length < 6) {
      return false;
    }
    return new_password === confirm_password;
  }

  renderTextField = (label, val, change, md, type = 'password') => (
    <Grid item xs={12} md={md}>
      <TextField
        error={!this.checkPwEquality()}
        label={label}
        fullWidth
        value={val || ''}
        onChange={e => this.handleChange(change, e)}
        margin="normal"
        required
        type={type}
        helperText={
          !this.checkPwEquality() ? 'Your password must be at least 6 characters long and match the confirmation' : false
        }
      />
    </Grid>
  );

  render() {
    const {
      classes,
      handleClose,
    } = this.props;
    const {
      new_password,
      confirm_password,
    } = this.state;

    return (
      <div>
        <DialogContent classes={{ root: classes.container }}>
          <Grid container spacing={containerSpacing}>
            <Grid item xs={12}>
              <Typography variant="h2">Password</Typography>
              <Divider />
              <Grid container spacing={containerSpacing}>
                {this.renderTextField('New Password', new_password, 'new_password', 12)}
                {this.renderTextField('Confirm New Password', confirm_password, 'confirm_password', 12)}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleSave}
            disabled={!this.checkPwEquality()}
          >
            Save
          </Button>
        </DialogActions>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    null,
    {
      openSecondaryModal: openSecondaryDocumentControllerModal,
    },
  ),
)(PasswordSetup);
