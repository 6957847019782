export const SET_USER = 'SET_USER';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_OFFICE = 'SET_OFFICE';
export const OPEN_DOCUMENT_CONTROLLER_MODAL = 'OPEN_DOCUMENT_CONTROLLER_MODAL';
export const CLOSE_DOCUMENT_CONTROLLER_MODAL = 'CLOSE_DOCUMENT_CONTROLLER_MODAL';
export const OPEN_SECONDARY_DOCUMENT_CONTROLLER_MODAL = 'OPEN_SECONDARY_DOCUMENT_CONTROLLER_MODAL';
export const CLOSE_SECONDARY_DOCUMENT_CONTROLLER_MODAL = 'CLOSE_SECONDARY_DOCUMENT_CONTROLLER_MODAL';
export const SET_CREDIT_REQUEST = 'SET_CREDIT_REQUEST';
export const SET_CARRIER_REQUEST = 'SET_CARRIER_REQUEST';
export const RESET_REQUEST = 'RESET_REQUEST';
