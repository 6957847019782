import {
  CLOSE_DOCUMENT_CONTROLLER_MODAL,
  OPEN_DOCUMENT_CONTROLLER_MODAL,
  CLOSE_SECONDARY_DOCUMENT_CONTROLLER_MODAL,
  OPEN_SECONDARY_DOCUMENT_CONTROLLER_MODAL,
} from '../constants';

export const openDocumentControllerModal = children => (dispatch) => {
  dispatch({
    type: OPEN_DOCUMENT_CONTROLLER_MODAL,
    children,
  });
};

export const closeDocumentControllerModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_DOCUMENT_CONTROLLER_MODAL,
  });
};

export const openSecondaryDocumentControllerModal = secondaryChildren => (dispatch) => {
  dispatch({
    type: OPEN_SECONDARY_DOCUMENT_CONTROLLER_MODAL,
    secondaryChildren,
  });
};

export const closeSecondaryDocumentControllerModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_SECONDARY_DOCUMENT_CONTROLLER_MODAL,
  });
};
