import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import firebase from 'firebase/app';
import 'firebase/storage';
import { rowStyle, even } from '../../rowStyle';
import { containerSpacing } from '../../common';

import ServerTime from '../../containers/serverTime';

const dropZoneStyle = {
  width: '100%',
  minHeight: '100px',
  borderWidth: '2px',
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: 'solid',
};

const dropzoneStyles = () => ({
  addBtn: {
    margin: 10,
    textAlign: 'right',
  },
  center: {
    textAlign: 'center',
    top: '40%',
    position: 'relative',
  },
  ...rowStyle,
});

export const clickHandler = (e, file) => {
  e.preventDefault();
  e.stopPropagation();

  const ref = file.ref ? file.ref : file;
  // Create a reference with an initial file path and name
  const storage = firebase.storage();
  const gsReference = storage.refFromURL(ref);
  gsReference
    .getDownloadURL()
    .then((url) => {
      window.open(url, '_blank');
    })
    .catch((error) => {
      console.log('error', error);
    });
};

class DocumentDropzone extends Component {
  state = {
    uploading: false,
  }

  renderActionButton = (file, doc_type, disabled, func, color, label) => (
    <Button
      color={color}
      variant="contained"
      onClick={() => func(file.name, doc_type)}
      disabled={disabled}
    >
      {label}
    </Button>
  );

  handleDrop = (uploadFiles, doc_type) => {
    const { onDrop } = this.props;
    this.setState({ uploading: true });
    return onDrop(uploadFiles, doc_type, ServerTime)
      .then(() => this.setState({ uploading: false }));
  }

  render() {
    const {
      classes, doc_type, docs, onApprove, onDelete, onVoid, disabled,
    } = this.props;
    const { uploading } = this.state;
    return (
      <div>
        <Dropzone
          onDrop={uploadFiles => this.handleDrop(uploadFiles, doc_type)}
          style={dropZoneStyle}
          disableClick
          disabled={disabled}
        >
          {({ open }) => (
            <Grid container spacing={containerSpacing}>
              <Grid item xs={12}>
                {docs && docs.length === 0 && (
                  <h4 className={classes.center}>Drop files here.</h4>
                )}
                {docs && docs.length > 0 && (
                  <List dense>
                    {docs.map((file, index) => (
                      <ListItem button key={`${file.ref}`} classes={even(index, classes)}>
                        <ListItemText
                          inset
                          primary={file.name}
                          onClick={e => clickHandler(e, file)}
                        />
                        {!uploading
                          && onApprove
                          && this.renderActionButton(file, doc_type, disabled, onApprove, 'primary', 'approve')
                        }
                        {!uploading
                          && onVoid
                          && this.renderActionButton(file, doc_type, disabled, onVoid, 'secondary', 'void')
                        }
                        {!uploading
                          && onDelete
                          && this.renderActionButton(file, doc_type, disabled, onDelete, 'default', 'delete')
                        }
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                classes={{
                  item: classes.addBtn,
                }}
              >
                {uploading
                  && (
                    <Button
                      disabled
                      variant="contained"
                      color="default"
                    >
                      Uploading...
                      <CircularProgress size={20} />
                    </Button>
                  )
                }
                {!uploading
                  && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => open()}
                      disabled={disabled}
                    >
                      Upload
                    </Button>
                  )
                }
              </Grid>
            </Grid>
          )}
        </Dropzone>
      </div>
    );
  }
}

DocumentDropzone.propTypes = {
  disabled: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
  doc_type: PropTypes.string,
  docs: PropTypes.array,
  onApprove: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  onVoid: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  classes: PropTypes.object.isRequired,
};

DocumentDropzone.defaultProps = {
  doc_type: '',
  disabled: false,
  docs: [],
  onApprove: null,
  onDelete: null,
  onVoid: null,
};

export default withStyles(dropzoneStyles)(DocumentDropzone);
