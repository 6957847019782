import React, { Component } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import ArrowBack from '@material-ui/icons/ArrowBack';
// import LockOutline from '@material-ui/icons/LockOutline';

// core components
import GridContainer from '../../components/mdpr/Grid/GridContainer';
import GridItem from '../../components/mdpr/Grid/GridItem';
import Button from '../../components/mdpr/CustomButtons/Button';
import CustomInput from '../../components/mdpr/CustomInput/CustomInput';
import Card from '../../components/mdpr/Card/Card';
import CardBody from '../../components/mdpr/Card/CardBody';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';

class RegisterPage extends Component {
  state = {
    checked: [],
  };

  propTypes = {
    classes: PropTypes.object.isRequired,
    toggleForgotPassword: PropTypes.func.isRequired,
    handleForgotPassword: PropTypes.func.isRequired,
  };

  handleToggle = (value) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.currentTarget.id]: event.currentTarget.value,
    });
  };

  render() {
    const { classes, toggleForgotPassword, handleForgotPassword } = this.props;
    const { email } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Card className={classes.cardSignup}>
              <GridContainer justify="center">
                <GridItem xs={1}>
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => toggleForgotPassword()}
                    onKeyDown={() => toggleForgotPassword()}
                    className={classes.arrowBack}
                  >
                    <ArrowBack className={classes.inputAdornmentIcon} />
                  </div>
                </GridItem>
                <GridItem xs={9}>
                  <h2 className={classes.cardTitle}>Reset Password</h2>
                </GridItem>
              </GridContainer>
              <CardBody>
                <form onSubmit={() => handleForgotPassword(email)} className={classes.form}>
                  <CustomInput
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      required: true,
                      placeholder: 'Email...',
                      onChange: event => this.handleChange(event),
                    }}
                  />
                  <div className={`${classes.center} ${classes.btnMargin}`}>
                    <Button round color="primary" type="submit">
                      Reset Password
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(registerPageStyle)(RegisterPage);
