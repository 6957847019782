import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CAStates, USStates } from '../../dropdown_options/index';
import { US } from '../../common';

const RenderStatesComponent = ({
  change_state, country, handleChange, state,
}) => {
  if (country === 'Canada') {
    return <CAStates change_state={change_state} handleChange={handleChange} state={state} />;
  }
  if (country === US) {
    return <USStates change_state={change_state} handleChange={handleChange} state={state} />;
  }
  return (
    <MenuItem value="">
      <em />
    </MenuItem>
  );
};

RenderStatesComponent.propTypes = {
  change_state: PropTypes.string,
  country: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  state: PropTypes.string,
};

RenderStatesComponent.defaultProps = {
  change_state: '',
  state: '',
};

export default withStyles({})(RenderStatesComponent);
