import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import PropTypes from 'prop-types';

import { createBrowserHistory } from 'history';
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { connect } from 'react-redux';
import { setAuthUser } from '../actions';

import indexRoutes from '../routes';
import publicRoutes from '../routes/public';
import publicOnlyRoutes from '../routes/publiconly';

const hist = createBrowserHistory();

const AuthRoute = ({
  // eslint-disable-next-line react/prop-types
  component: Component,
  // eslint-disable-next-line react/prop-types
  user,
  // eslint-disable-next-line react/prop-types
  authuser,
  // eslint-disable-next-line react/prop-types
  type,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (authuser === undefined) {
        // display this until the login state has been determined
        return <div className="signin" />;
      }
      if (authuser && user) {
        // if the user profile has been loaded
        if (type === 'private') {
          return <Component {...props} />;
        }
        if (type === 'publiconly') {
          return <Redirect to="/dashboard" />;
        }
      }
      if (!authuser) {
        // if the user is not logged in
        if (type === 'private') {
          return <Redirect to="/login" />;
        }
        if (type === 'publiconly') {
          return <Component {...props} />;
        }
      }
      // display this until the user profile is loaded
      return null;
    }}
  />
);

class Routes extends React.Component {
  state = {};

  static propTypes = {
    user: PropTypes.object,
    authuser: PropTypes.object,
    doSetUser: PropTypes.func.isRequired,
  };

  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    children: <span />,
    user: undefined,
    authuser: undefined,
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      const u = !user
        ? null
        : user;
      console.log('got auth user ', u);
      const { doSetUser } = this.props;

      doSetUser(u);
    });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  signout = () => {
    firebase.auth().signOut();
  };

  render() {
    const {
      user,
      authuser,
    } = this.props;

    return (
      <Router history={hist} signout={this.signout}>
        <Switch>
          {publicOnlyRoutes.map(prop => (
            <AuthRoute
              path={prop.path}
              component={prop.component}
              key={prop.name}
              user={user}
              authuser={authuser}
              type="publiconly"
            />
          ))}
          {publicRoutes.map(prop => (
            <Route path={prop.path} component={prop.component} key={prop.name} />
          ))}
          {indexRoutes.map(prop => (
            <AuthRoute
              path={prop.path}
              component={prop.component}
              key={prop.name}
              user={user}
              authuser={authuser}
              type="private"
            />
          ))}
        </Switch>
      </Router>
    );
  }

  /*
  render() {
    const { authuser, user, children } = this.props;
    if (authuser === undefined) {
      // display this until the login state has been determined
      return <div className="signin" />;
    }
    if (authuser && user) {
      // display this if the user profile has been loaded
      return children(this.signout);
    }
    if (!authuser) {
      // display this if the user is not logged in
      return <Login />;
    }
    // display this until the user profile is loaded
    return null;
  }
  */
}

const mapStateToProps = state => ({
  authuser: state.user.authuser,
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  {
    doSetUser: setAuthUser,
  },
)(Routes);
