
function frozenClone(obj) {
  const clone = {};
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (Array.isArray(val)) {
      clone[key] = Object.freeze(val.slice());
    } else if (val instanceof Date) {
      clone[key] = new Date(val.getTime());
    } else if (key !== null && typeof val === 'object') {
      clone[key] = frozenClone(val);
    } else {
      clone[key] = val;
    }
  });
  return Object.freeze(clone);
}

export default frozenClone;
