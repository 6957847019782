import {
  RESET_REQUEST,
  SET_CREDIT_REQUEST,
  SET_CARRIER_REQUEST,
} from '../constants';

const initialState = {
  creditrequest: {},
  truckboard_team_request: {},
};

const createLoadReducer = (state = initialState, action) => {
  const { creditrequest, truckboard_team_request } = state;
  const { data } = action;
  switch (action.type) {
    case SET_CREDIT_REQUEST:
      if (creditrequest.id === data.id) {
        return {
          truckboard_team_request,
          creditrequest: {},
        };
      }
      return {
        truckboard_team_request,
        creditrequest: data,
      };
    case SET_CARRIER_REQUEST:
      if (truckboard_team_request.id === data.id) {
        return {
          truckboard_team_request: {},
          creditrequest,
        };
      }
      return {
        truckboard_team_request: data,
        creditrequest,
      };

    case RESET_REQUEST:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default createLoadReducer;
