import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import {
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const removeAddons = (str = '') => str.replace(/[- )(]/g, '').trim();

export const TextMaskCustom = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = () => ({
  formControl: {
    marginTop: 16,
  },
});

const PhoneComponent = ({
  changeValue,
  classes,
  disabled,
  handleChange,
  label,
  required,
  value,
}) => (
  <FormControl
    required={required}
    fullWidth
    className={classes.formControl}
    disabled={disabled}
  >
    <InputLabel>{label}</InputLabel>
    <Input
      value={value || ' '}
      onChange={e => handleChange(changeValue, e)}
      inputComponent={TextMaskCustom}
    />
  </FormControl>
);

PhoneComponent.propTypes = {
  changeValue: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
};

PhoneComponent.defaultProps = {
  disabled: false,
  required: true,
  value: ' ',
};

export default withStyles(styles)(PhoneComponent);
